import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@/components/Languages/';
import { selectItem } from "@/store/app/getters";
import { useApiQuery } from "@/api";
import { keyBy } from "@/helpers/keyBy";
import styles from "./HeaderCart.module.css";
import { ReactSVG } from "react-svg";
import icons from "@/assets/icons";
import classNames from "classnames";
import { Modal } from "@/components/Modal";
import { Cart } from "@/components/Cart/Cart";
import { Billing } from "@/components/Cart/Billing";
// import {Country} from "@/components/Country/Coutry";
import { CountryStatic } from "../Country/CountryStatic";
import { Price } from '@/components/Price';
import CartIcon from '@/components/Cart/CartIcon';
import { setItem } from '@/store/app/actions';
import links from '@/data/links';

const { pages } = links;

const path = '/product-ids?ids[]=';

const OverlayContent = ({ isCartFull }) => {
  const { t } = useTranslation('main');

  return (
    <span className={styles.cartOverlayText}>
      {isCartFull ? (
        <>{t('proceed_to_checkout')} <br /> {t('or')}</>
      ) : (t('your_cart_is_empty'))}
      <br /> <span className={classNames(styles.cartOverlayLink, 'ga-cart-bnt-continue')}>{t('continue_shopping')}</span>
    </span>
  )
}

const HeaderCart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('main');
  const node = useRef(null);
  const { cart = [], country } = useSelector(selectItem(['user'], {}));
  const countries = useSelector(selectItem(['countries'], {}));
  const { isCartOpen, isShowText } = useSelector(selectItem(['statuses'], {}));

  const viewport = useSelector(selectItem(['viewport'], []));
  const winWidth = window.innerWidth;

  useEffect(() => {
    if (isCartOpen) document.getElementById('root').classList.add('root-cart-open');
    else document.getElementById('root').classList.remove('root-cart-open');

    window.dispatchEvent(new Event('resize'));
  }, [isCartOpen]);

  const ids = cart.map(i => i.id).join('&ids[]=');
  const { data } = useApiQuery({
    name: ['products'],
    path: path + ids,
    skip: !ids,
    mapper: ({ items = [] }, before = {}) => {
      const products = items.reduce((acc, p) => { acc[p.id] = p; return acc }, {});

      return Object.assign({}, before, products);
    },
  })

  const shipping = countries[country];
  let total = cart.reduce((acc, { id }) => acc + keyBy(data, [id, 'price', 'en'], 0), 0);
  const isCartFull = cart.length && Object.keys(cart).length && total > 0;

  if (isCartFull && shipping) {
    total += shipping.cost;
  }

  return (
    <>
      <div ref={node} />
      {(isShowText || viewport !== 'sm') &&
        <span className={styles.ship}>
          {isCartFull ? (
            <>
              <span className={styles.cartInfo}>{viewport === 'sm' ? t('qty') : t('quantity')}: <b>{cart.length}</b></span>
              <span className={styles.cartInfo}>{viewport === 'sm' ? t('ttl') : t('total')}: <b><Price value={total} /></b></span>
            </>
          ) : <CountryStatic />}
        </span>
      }
      <div className={classNames(styles.cart, 'ga-cart-icon')} onClick={() => dispatch(setItem(['statuses', 'isCartOpen'], !isCartOpen))}>
        {/* <ReactSVG src={icons.cart} className={classNames(styles.cartIcon, styles.cartBase)}/>
        <div className={styles.cartInner} style={{ height: cart.length === 1 ? `21%` : `calc(100% * ${cart.length / 4})` }}>
          <ReactSVG src={icons.cart} className={classNames(styles.cartIcon, styles.cartFull)}/>
        </div> */}

        <CartIcon filling={cart.length === 1 ? 21 : cart.length / 4 * 100} className={classNames(styles.cartIcon, styles.cartBase)} />
      </div>
      <Modal
        classNameWrapper={'cart-popup'}
        className={styles.modal}
        classNameContent={classNames(styles.modalContent, 'ga-view-cart')}
        target={node}
        side='right'
        open={isCartOpen}
        stopScroll={winWidth < 1536}
        onClose={() => dispatch(setItem(['statuses', 'isCartOpen'], false))}
        overlay={winWidth < 1536 && {
          background: `${process.env.PUBLIC_URL}/images/cartbg.gif`,
          content: <OverlayContent isCartFull={isCartFull} />,
          class: isCartFull ? 'cart' : 'cart-empty'
        }}
      >
        {isCartFull ?
          <>
            <Cart />
            <div className={styles.modalFooter}>
              {isCartFull ? <Billing total={total} /> : null}
              <span className={styles.modalText}>
                <Trans
                  i18nKey="by_registring_you_agree"
                  components={{
                    privacy_tag: <Link className={styles.modalLink} to={pages['privacy-and-cookie-policy'].url} target='_blank' />,
                    terms_tag: <Link className={styles.modalLink} to={pages['terms-and-conditions'].url} target='_blank' />
                  }}
                />
              </span>
              <ReactSVG className={styles.cards} src={icons.cards} />
            </div>
          </>
          : (winWidth >= 1536 ?
            <div className={styles.cartEmptyText}>
              {t('your_cart_is_empty')}
              <br />
              <span
                className={classNames(styles.cartEmptyLink, 'ga-cart-bnt-continue')}
                onClick={() => dispatch(setItem(['statuses', 'isCartOpen'], !isCartOpen))}
              >{t('continue_shopping')}</span>
            </div>
            : null)
        }
      </Modal>
    </>
  )
};

export default HeaderCart;
