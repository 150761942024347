import { useState } from "react";
import { Modal } from "@/components/Modal";
import styles from './Modal.module.css';
import classNames from "classnames";
import {Header} from "@/components/Header";
import icons from "@/assets/icons";
import { ReactSVG } from "react-svg";

const ModalList = ({ control: Control, items }) => {
  const [open, setOpen] = useState(false);

  const close = <div className={styles.close} onClick={() => setOpen(false)}><ReactSVG src={icons.close} /></div>;
  const handleClick = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  const currentItem = items?.find(item => item.isCurrent);

  return (
    <Modal classNameWrapper={styles.modal} open={open} control={(e) => <Control onClick={(e) => handleClick(e)} />} side='full'>
      <Header right={close} />
      <div className={styles.list}>
        {currentItem &&
          <>
            <span
              className={classNames(styles.item, styles.itemCurrent, `ga-modal-item-${currentItem.value}`)}
              onClick={e => {
                currentItem.onClick && currentItem.onClick(e);
                setOpen(false);
              }}
              children={currentItem.name}
            />
            <span className={styles.divider}>-</span>
          </>
        }
        {items?.map(({ name, value, isCurrent, isActive, onClick, href }, index) => (
          (!isCurrent && name) && (
            name === 'divider' ? (
              <span key={index} className={styles.divider}>-</span>
            ) : (
              <a
                key={index}
                href={href}
                className={classNames(styles.item, isActive && styles.itemActive, `ga-modal-item-${value}`)}
                onClick={e => {
                  onClick && onClick(e);
                  setOpen(false);
                }}
                children={name}
              />
            )
          )
        ))}
      </div>
    </Modal>
  )
}

export default ModalList;