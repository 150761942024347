import { useState } from 'react';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import LoadingImage from "@/components/Loader/loading.gif";
import './LazyLoad.css'

export const LazyPlaceholder = () => {
  const { t } = useTranslation('main');
  return <img src={LoadingImage} className="lazyload-loader" alt={t('loading')} />;
};

export const LazyImage = ({classNameWrapper, className, src, alt }) => {
  const [isLoaded, setLoaded] = useState(null);

  return (
    <LazyLoad className={classNames(classNameWrapper, 'lazyload-wrapper--image')} placeholder={<LazyPlaceholder />}>
        {!isLoaded && <LazyPlaceholder />}
        <img
          className={classNames(className, !isLoaded && 'lazyload-image')}
          alt={alt}
          src={src}
          onLoad={() => setLoaded(true)}
        />
    </LazyLoad>
  );
};
