import {Main} from '@/pages/Main';
import {OrderStatus} from "@/pages/OrderStatus";
import {OrderReturn} from "@/pages/OrderReturn";
import {OrderCancel} from "@/pages/OrderCancel";
import {Request} from "@/pages/Request";
import {DispatchContainer} from "@/pages/DispatchContainer";

const localesString = "/:locale(en|ru)?";

export const routes = [
  { path: localesString + '/', component: Main, exact: true },
  { path: localesString + '/series/:slug', component: Main },
  // { path: localesString + '/page/:slug', component: PageContainer },
  { path: localesString + '/order-status', component: OrderStatus },
  { path: localesString + '/order-return', component: OrderReturn },
  { path: localesString + '/order-cancel', component: OrderCancel },
  { path: localesString + '/request', component: Request },
  // { path: localesString + '/:slug', component: ProductContainer },
  { path: localesString + '/:slug', component: DispatchContainer },
];
