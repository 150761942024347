import { useSelector } from 'react-redux';
import { selectItem } from '@/store/app/getters';
import Currency from './Currency';

const Price = ({ value, showCurrency = true }) => {
    const countries = useSelector(selectItem(['countries'], {}));
    const country = useSelector(selectItem(['user', 'country']));
    const course = (countries[country] && countries[country].course) ? countries[country].course : 1;
    const cost = (value * course).toFixed(0);

    if (country === 'RU') return <>{cost} {showCurrency && <Currency />}</>;
    else return <>{showCurrency && <Currency />} {cost}</>;
}

export default Price;
