import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { rootPath } from '@/api';
import { useApiQuery } from '@/api';
import { keyBy, setTo } from '@/helpers/keyBy';
import PagesList from '@/components/PagesList';
import { HeaderTop } from '@/components/Header';
import { Container, Row } from '@/components/Grid';
import { Input } from '@/components/Input/Input';
import { Button, mode } from '@/components/Button';
import { LoaderContext } from '@/context/loaderState';
import { Notice } from '@/components/Notice/Notice';
import { SelectItemsTable } from '@/components/Order';
import styles from './OrderStatus.module.css';
import links from '@/data/links';

const { pages, social } = links;

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

const path = '/order-status';

const createSchema = (inputs) => {
  let schema = {};

  inputs.map(({ name }) => {
    if (name === 'email') {
      schema[name] = yup.string().email('invalid_email').required('required_err');
    } else if (name === 'phone') {
      schema[name] = yup.string().matches(/[0-9 +()]/g, 'invalid_phone').required('required_err');
    } else {
      schema[name] = yup.string().required('required_err');
    }

    return null;
  });

  return schema;
}

export const OrderCancel = () => {
  const { t } = useTranslation(['main']);
  const { toggleLoading, toggleOverlay } = useContext(LoaderContext);
  const [ isSendingError, setSendError ] = useState(false);

  const history = useHistory();
  const orderId = new URLSearchParams(window.location.search).get('orderId');

  const { data = {}, isFail } = useApiQuery({
    name: ['orderStatus', orderId],
    path: `${path}?orderId=${orderId}`,
    onLoadEnd: () => {
      toggleOverlay(false);
      toggleLoading(false);
    }
  });

  useEffect(() => {
    if (!orderId || isFail) history.push(pages['order-status'].url);
    // eslint-disable-next-line
  }, [data]);

  const inputs = [
    { name: 'comment', component: Input, props: { label: '', placeholder: t('type_your_reason'), inputClass: styles.input } },
  ];

  const { handleSubmit, register, setValue, formState: { errors } } = useForm({
    resolver: async data => {
      try {
        const validationSchema = yup.object(createSchema(inputs));
        const values = await validationSchema.validate(data, { abortEarly: false });

        return { values, errors: {} };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    }
  });

  const onSubmit = formData => {
    toggleLoading(true);
    setSendError(false);

    const items = Object.values(selectedItems).map(({ id, checked }) => {
      if (!checked) return null;
      const product = order.products.find(item => item.id === id);
      if (!product) return null;
      return product;
    });

    const data = {
      orderId: orderId,
      comment: formData.comment,
      products: items
    };

    fetch(rootPath + '/order-cancel', {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }).then(async res => {
      toggleLoading(false);
      if (res.status === 200) history.push(`${pages['order-status'].url}?orderId=${orderId}`);
      else setSendError(true);
    }).catch(error => {
      setSendError(true);
    });
  };

  const { error, order } = data;

  const [selectedItems, setSelectedItems] = useState({});
  const onSelectItem = (id, checked) => {
    let next = {};
    setTo(next, [id], { id: id, checked: checked });
    setSelectedItems({...selectedItems, ...next});

    setValue(`products[${id}]`, checked);
  }

  const onSelectAll = (checked) => {
    let next = {};
    order.products.map(({ id }) => setTo(next, [id], { id: id, checked: checked }));
    setSelectedItems({...selectedItems, ...next});

    order.products.map(({ id }) => setValue(`products[${id}]`, checked));
  }

  return (
    <>
      <HeaderTop top={<PagesList title={t('custom_service')} page={'order-cancel'} />} showFilter={true} showCartInfo={false}>
        <Container>
          <Row noMargin={true}>
            <div className={styles.root}>
              <h1 className={styles.title}>{t('order_cancellation_request', {id: orderId})}{t('order')}</h1>
              {error &&
                <h4>{t('order_not_found_error')}</h4>
              }
              {order &&
                <div className="wysiwyg-content">
                  <h3 className={styles.subtitle}>{t('select_items_to_cancel')}</h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <p>{t('since_your_order_has_not_processed')}</p>
                    <p>{t('please_use_the_table_to_cancel')}</p>

                    <div className={styles.tableWrapper}>
                      <SelectItemsTable
                        products={order.products}
                        onSelectAll={onSelectAll}
                        onSelectItem={onSelectItem}
                        selectedItems={selectedItems}
                      /> 
                    </div>

                    <p>{t('tell_us_why_you_want_cancel')}</p>

                    {inputs.map(({ name, props, component: Component }, index) => (
                      <Component
                        {...props}
                        {...register(name)}
                        key={index}
                        error={t(keyBy(errors, [name, 'message'], null))}
                      />
                    ))}

                    <p>
                      <Trans
                        i18nKey="we_well_check_your_cancellation"
                        components={{
                          tag: <Link className="underline" to={pages['order-status'].url} />,
                        }}
                      />
                    </p>
                    <p>
                      <Trans
                        i18nKey="you_can_find_more_info_cancelling"
                        components={{
                          cancel_tag: <Link className="underline" to={pages['customer-service'].url + pages['customer-service'].url + '#cancellations'} />,
                          service_tag: <Link className="underline" to={pages['customer-service'].url} />,
                        }}
                      />
                    </p>

                    <div className={styles.btnContainer}>
                      <Button className={styles.btn} mode={mode.primary}>{t('submit_cancelation_request')}</Button>
                      {isSendingError && <span className={styles.sendingError}>{t('error_sending_request')}</span>}
                    </div>
                  </form>
                </div>
              }

              <hr className={styles.divider} />
              <div className={styles.support}>
                {t('if_you_have_any_questions')} <a href={`mailto:${social.service_email.url}`}>{social.service_email.url}</a>
              </div>
            </div>
          </Row>
        </Container>
      </HeaderTop>
      <Notice />
    </>
  )
}
