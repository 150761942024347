import { Link } from '@/components/Languages';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@/api';
import { keyBy } from '@/helpers/keyBy';
import { Price, Currency } from '@/components/Price';
import styles from './OrderTables.module.css';

const path = '/product-ids?ids[]=';

const OrderTotalTable = ({ products, delivery }) => {
  const { t } = useTranslation(['main']);

  const ids = Array.from(new Set(products.map(i => i.id))).join('&ids[]=');
  const { data } = useApiQuery({
    name: ['products'],
    path: path + ids,
    skip: !ids,
    mapper: ({ items = [] }, before = {}) => {
      const products = items.reduce((acc, p) => { acc[p.id] = p; return acc }, {});

      return Object.assign({}, before, products);
    },
  })

  const total = products.reduce((acc, { id }) => acc + keyBy(data, [id, 'price', 'en'], 0), 0);

  if (!data) return null;

  return (
    <div className="table-wrapper">
    <table>
      <tbody>
        <tr>
          <th className={styles.head}>{t('products')}</th>
          <th width="19.5%" className={styles.head}>{t('id')}</th>
          <th width="16%" className={styles.head}>{t('size')}</th>
          <th width="16%" className={styles.head}>{t('qty')}</th>
          <th width="16%" className={styles.head}>{t('price')}(<Currency />)</th>
        </tr>
        {products.map(({ id, size, count, amount }, index) => (
          <tr key={id + index}>
            <td>
              {data[id] && <Link to={`/${data[id].slug}`}><b>{data[id].style_id}</b></Link>}
            </td>
            <td>{data[id] && data[id].slug}</td>
            <td>{size}</td>
            <td>{count}</td>
            <td>{amount && <Price value={amount / count} showCurrency={false} />}</td>
          </tr>
        ))}
        {delivery && delivery.cost ? (
          <>
            <tr>
              <td colSpan="4" className={styles.totalText}>{t('shipment')}</td>
              <td><Price value={delivery.cost} showCurrency={false} /></td>
            </tr>
            <tr>
              <td colSpan="4" className={styles.totalText}>{t('subtotal')}</td>
              <td><Price value={total} showCurrency={false} /></td>
            </tr>
            <tr>
              <td colSpan="4" className={styles.totalText}><b>{t('total')}</b></td>
              <td><b><Price value={total + delivery.cost} showCurrency={false} /></b></td>
            </tr>
          </>
        ) : (
          <tr>
            <td colSpan="4" className={styles.totalText}><b>{t('total')}</b></td>
            <td>{total}</td>
          </tr>
        )}
      </tbody>
    </table>
    </div>
  );
};

export default OrderTotalTable;
