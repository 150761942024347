import {useMemo, useEffect, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {Helmet} from 'react-helmet';
import {Products} from '@/components/Products';
import AnchorsMenu from '@/components/AnchorsMenu';
import {Slider} from '@/components/Slider/Slider';
import {HeaderTop} from '@/components/Header';
import ProductsFilter from '@/components/Products/ProductsFilter';
import {selectItem} from '@/store/app/getters';
import {Notice} from '@/components/Notice/Notice';
import {Social} from '@/components/Footer';
import {LoaderContext} from '@/context/loaderState';

export const Main = () => {
  const [ activeItem, setActiveItem ] = useState(null);

  const { toggleOverlay } = useContext(LoaderContext);
  const { t } = useTranslation();
  const { search } = useLocation();
  const collection = new URLSearchParams(search).get('collection');

  const viewport = useSelector(selectItem(['viewport']));
  const collections = useSelector(selectItem(['collections'], {}));

  const slides = useMemo(() => {
    return Object.values(collections).filter((item) => item.video && item.video.length > 0 && item.showVideo);
  }, [collections]);

  const collectionId = useMemo(() => {
    if (!Object.values(collections).length) return null;
    const _collection = Object.values(collections).find((item) => (item.name && item.name.toLowerCase() === collection));
    return _collection ? _collection.id : 0;
  }, [collections, collection]);

  const menu = useMemo(() => {
    if (!slides) return null;

    const items = [];
    for (let i=0; i < slides.length; i++) {
      if (!slides[i]) continue;
      const { name } = slides[i];
      items.push({ id: name, title: name })
    }

    if (items.length) return [...items, { id: 'products', title: t('products') }];
  }, [slides, t]);

  useEffect(() => {
    if (!slides || !slides.length) return null;
    setTimeout(() => { toggleOverlay(false) }, 650);
  }, [slides, toggleOverlay]);

  return (
    <>
      <Helmet title="IDLE corp.™ uniform" />
      {slides && <Slider items={slides} active={activeItem} />}
      <HeaderTop top={<ProductsFilter title={t('shop')} />} showFilter={viewport !== 'sm'}>
        {collectionId !== null &&
          <Products
            id="products"
            className="products ga-products"
            listId="1"
            listName="main"
            collection={collection}
            collectionId={collectionId}
          />
        }
      </HeaderTop>
      <AnchorsMenu items={menu} active={activeItem} setActive={setActiveItem} />
      <Notice />
      <Social />
    </>
  );
}
