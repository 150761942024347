import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, mode } from '@/components/Button';
import styles from './Slider.module.css';
import { baseImgUrl } from '@/api';
import shop_now from '@/assets/images/shop_now.svg';

export const SlideBody = forwardRef(({ name = '', title, className }, ref) => {
  const { t } = useTranslation('main');

  return (
    <div ref={ref} className={classNames(styles.content, className)}>
      {title &&
        <img src={baseImgUrl + title} className={styles.title} alt={title} />
      }
      <Button mode={mode.none} to={`/?collection=${name.toLowerCase()}#products`} className={styles.btn}>
        <img src={shop_now} className={styles.btnImage} alt={t('shop_now')} />
      </Button>
    </div>
  )
});
