import styles from './InputSelectState.module.css';
import { forwardRef } from "react";
import { useTranslation } from 'react-i18next'
import { InputError } from "@/components/Input/InputError";
import classNames from "classnames";

export const InputSelectState = forwardRef(({ name, error, label, value, onChange, options = [] }, ref) => {
    const { t } = useTranslation();
    const errorText = t(`errors.${error}`);

    return (
        <label className={styles.root}>
            <InputError error={error}>
                {(label || error) &&
                    <span className={classNames(styles.label, error && styles.labelError)}>
                        {label}
                        <span className={styles.labelErrorText}>{error && `(${errorText})`}</span>
                    </span>
                }
                <div className={styles.selectWrapper}>
                    <select
                        name={name}
                        className={classNames(styles.input, styles.select)}
                        value={value}
                        onChange={onChange}
                        ref={ref}
                        required>
                        <option selected disabled hidden value="">Select state</option>
                        {options.map((value, i) => <option key={i} value={value}>{value}</option>)}
                    </select>
                </div>
            </InputError>
        </label>
    )
})