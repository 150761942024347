import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LoaderContext = createContext({
  isShowOverlay: true,
  setLoadingOverlay: () => {},
  isLoading: true,
  setLoading: () => {},
});

const LoaderState = ({ children }) => {
  const [isShowOverlay, setLoadingOverlay] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const toggleLoading = (status) => {
    setLoading(status);
  }

  const toggleOverlay = (status) => {
    setLoadingOverlay(status);
  }

  return (
    <LoaderContext.Provider value={{ isShowOverlay, toggleOverlay, isLoading, toggleLoading }}>{children}</LoaderContext.Provider>
  );
};

LoaderState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoaderState;
