// import {useCallback, useState, useEffect} from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import classNames from 'classnames';
import { rootPath } from "@/api";
import { Column, Row } from "@/components/Grid";
// import { InputSelect } from "@/components/Input/InputSelect";
import { InputAddressLine } from "@/components/Input/InputAddressLine";
import { InputSelectState } from "@/components/Input/InputSelectState";
import { Input } from "@/components/Input/Input";
import { InputCountry } from "@/components/Input/InputCountry";
import { Button, mode } from "@/components/Button";
import CartTotal from "@/components/Cart/CartTotal";
import { Price } from "@/components/Price";
import { setItem } from "@/store/app/actions";
import { selectItem } from "@/store/app/getters";
import * as yup from "yup";
import styles from './Billing.module.css';
import { keyBy } from "@/helpers/keyBy";
import { ReactSVG } from 'react-svg';
import icons from '@/assets/icons';
// import {InputError} from "@/components/Input/InputError";

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

const createSchema = (inputs, isBillingOpen) => {
  let schema = {};

  inputs.map(({ name }) => {
    if (name === 'email') {
      schema[name] = yup.string().email('invalid_email').required('required_err');
      if (isBillingOpen) schema[`billing_${name}`] = yup.string().email('invalid_email').required('required_err');
    } else if (name === 'phone') {
      schema[name] = yup.string().matches(/[0-9 +()]/g, 'invalid_phone').required('required_err');
      if (isBillingOpen) schema[`billing_${name}`] = yup.string().matches(/[0-9 +()]/g, 'invalid_phone').required('required_err');
    } else if (name === 'address (second line)') {
      schema[name] = yup.string().optional();
    } else {
      schema[name] = yup.string().required('required_err');
      if (isBillingOpen) schema[`billing_${name}`] = yup.string().required('required_err');
    }

    return null;
  });

  return schema;
}

const BillingCheckbox = ({ id, onChange, checked, label }) => {
  return (
    <div className={styles.checkbox}>
      <input
        id={id}
        type="checkbox"
        className={styles.checkboxInput}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} className={styles.checkboxLabel} tabIndex="0">
        {label}
      </label>
    </div>
  );
}

export const Billing = ({ total }) => {
  const { t } = useTranslation('main');
  const [isShippingOpen, setShippingOpen] = useState(false);
  const [isBillingOpen, setBillingOpen] = useState(false);
  const [isShippingRemember, setShippingRemember] = useState(true);
  const cart = useSelector(selectItem(['user', 'cart']));
  const dispatch = useDispatch();

  // const countries = useSelector(selectItem(['countries'], {}));
  // const optionsCountry = Object.keys(countries).map(id => ({ value: id, name: countries[id].country }));

  const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];
  // const optionsStates = Object.keys(states).map(id => ({ value: states[id] }));
  // console.log(optionsStates)

  const shippingData = useSelector(selectItem(['user', 'shipping']));

  // const resolver = useYupValidationResolver(validationSchema);

  const inputs = [
    { span: 6, name: 'firstName', component: Input, props: { label: t('first_name'), placeholder: ' ' } },
    { span: 6, name: 'lastName', component: Input, props: { label: t('last_name'), placeholder: ' ' } },
    { span: 6, name: 'email', component: Input, props: { label: t('email'), placeholder: ' ' } },
    { span: 6, name: 'phone', component: Input, props: { label: t('phone'), placeholder: ' ', type: 'tel' } },
    { span: 6, name: 'country', component: InputCountry, props: { label: t('country'), value: 'US', placeholder: 'United States' } },
    { span: 6, name: 'zip', component: Input, props: { label: t('zipcode'), placeholder: ' ' } },
    { span: 6, name: 'state', component: InputSelectState, props: { options: states, label: t('state') } },
    { span: 6, name: 'city', component: Input, props: { label: t('city'), placeholder: ' ' } },
    { span: 12, name: 'address', component: Input, props: { label: t('address'), placeholder: ' ' } },
    { span: 12, name: 'address (second line)', component: InputAddressLine, props: { label: t('address (second line)') } },
  ];

  const { handleSubmit, register, watch, formState: { errors } } = useForm({
    resolver: async data => {
      try {
        const validationSchema = yup.object(createSchema(inputs, isBillingOpen));
        const values = await validationSchema.validate(data, { abortEarly: false });

        return { values, errors: {} };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    defaultValues: shippingData
  });


  // const countryInput = watch('country');

  // useEffect(() => {
  //   dispatch(setItem(['user', 'country'], countryInput));
  //   //eslint-disable-next-line
  // }, [countryInput])

  const stateInput = watch('state');

  // useEffect(() => {
  //   dispatch(setItem(['user', 'state'], stateInput));
  //   //eslint-disable-next-line
  // }, [stateInput])

  const onSubmit = userData => {
    if (isShippingRemember) dispatch(setItem(['user', 'shipping'], userData));

    fetch(rootPath + '/pay', {
      method: 'POST',
      headers,
      body: JSON.stringify({ userData, products: cart.map(i => ({ ...i, id: '' + i.id, count: '' + 1 })) })
    }).then(async res => {
      dispatch(setItem(['user', 'cart'], [], { isHard: true }))
      document.location = await res.text();
    })
  };

  if (!isShippingOpen) {
    return (
      <>
        <CartTotal className={styles.estimated} />
        <Button
          full
          className={classNames(styles.btn, 'ga-cart-btn-checkout')}
          mode={mode.primary}
          onClick={() => setShippingOpen(true)}
        >{t('checkout')} <Price value={total} /></Button>
      </>
    )
  }

  // const cardInputs = [
  //   { span: 6, name: 'credit-number', component: Input, props: { label: 'Card number', placeholder: '**** **** **** ****', type: 'tel', pattern: '\\d*', maxLength: '19' } },
  //   { span: 3, name: 'credit-expires', component: Input, props: { label: 'Date', placeholder: 'MM/YY', type: 'tel', pattern: '(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])', maxLength: '5' } },
  //   { span: 3, name: 'credit-cvc', component: Input, props: { label: 'CVC', placeholder: '***', type: 'password', pattern: '\\d*', maxLength: '4', autoComplete: 'cc-scs' } },
  // ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={classNames(styles.fields, 'ga-cart-buy-delivery-adr')}>
        <h4 className={styles.title}>{t('shipping_address')}</h4>
        <Row>
          {inputs.map(({ span, name, props, component: Component }, index) => (
            <Column span={span} key={index}>
              <Component
                {...props}
                {...register(name)}
                inputClass={`ga-cart-buy-delivery-adr-inpt-${name}`}
                error={t(keyBy(errors, [name, 'message'], null))}
              />
            </Column>
          ))}
        </Row>
        <div className={styles.checkboxes}>
          <BillingCheckbox
            id="remember_checkbox"
            label={t('remember_shipping_info')}
            checked={isShippingRemember}
            onChange={() => setShippingRemember(!isShippingRemember)}
          />
          <BillingCheckbox
            id="billing_checkbox"
            label={t('same_billing_address')}
            checked={!isBillingOpen}
            onChange={() => setBillingOpen(!isBillingOpen)}
          />
        </div>
      </div>
      {isBillingOpen &&
        <div className={classNames(styles.fields, 'ga-cart-buy-billing-adr')}>
          <h4 className={styles.title}>{t('billing_address')}</h4>
          <Row>
            {inputs.map(({ span, name, props, component: Component }, index) => (
              <Column span={span} key={index}>
                <Component
                  {...props}
                  {...register(`billing_${name}`)}
                  inputClass={`ga-cart-buy-billing-adr-inpt-${name}`}
                  error={t(keyBy(errors, [`billing_${name}`, 'message'], null))}
                />
              </Column>
            ))}
          </Row>
        </div>
      }
      {/* <div className={styles.fields}>
        <h4 className={styles.title}>Payment method</h4>
        <Row>
          {cardInputs.map(({ span, name, props, component: Component }, index) => (
            <Column span={span} key={index}>
              <Component
                {...props}
                {...register(name)}
                inputClass={`ga-cart-buy-payment-inpt-${name}`}
                error={keyBy(errors, [name, 'message'], null)}
              />
            </Column>
          ))}
        </Row>
      </div> */}
      <div className={classNames(styles.fields)}>
        <h4 className={styles.title}>{t('order_summary')}</h4>
        <CartTotal className={styles.estimated} />
      </div>
      <div className={classNames(styles.checkoutBtns)}>

        <Button
          className={classNames(styles.btnApplePay, 'ga-cart-btn-chekout')}
          mode={mode.main}
          type='submit'
          disabled={errors && Object.keys(errors).length}
        >
          <ReactSVG src={icons.applePayIcon} />
        </Button>

        <Button
          className={classNames(styles.btnMain, 'ga-cart-btn-chekout')}
          mode={mode.main}
          type='submit'
          disabled={errors && Object.keys(errors).length}
        >
          {t('checkout')} <Price value={total} />
        </Button>
      </div>
    </form>
  );
}
