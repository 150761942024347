import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import getStore from '@/store';
import {AnchorsSmoothScroll} from '@/components/AnchorsSmoothScroll';
import {Viewport} from "@/components/Viewport";
import {Root} from "@/Root";

export const App = (context) => {
  const user = JSON.parse(localStorage?.getItem('user'));
  const store = user ? getStore({user: user}) : getStore();

  const body = (
    <Provider store={store}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
      <AnchorsSmoothScroll />
      <Viewport />
      <div id='explore_full_story' />
      <div id='modal' />
    </Provider>
  );

  // if (context.serverSide) {
  //   return {
  //     renderedHtml: {
  //       componentHtml: renderToString(body),
  //       // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //       title: ReactHelmet.renderStatic().title.toString(),
  //     },
  //   };
  // }

  return body;
}

export default App;
