import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { selectItem } from '@/store/app/getters';
import styles from './Coutry.module.css';

export const CountryStatic = ({ label, className }) => {
    const { t } = useTranslation('main');

    const countries = useSelector(selectItem(['countries'], {}));
    const country = useSelector(selectItem(['user', 'country']));
    const viewport = useSelector(selectItem(['viewport'], []));

    return (
        <span className={classNames(styles.root, className)} style={{ 'cursor': 'default' }}>
            {label || <>{countries[country]?.description} {t('to')}</>}
            <span className='ga-view-lang' style={{ 'color': 'var(--primary)' }}>&thinsp;
                {label ? (
                    t('country_' + country)
                ) : (
                    <>
                        {viewport === 'sm' ? t(country) : t('to_' + country)}
                    </>
                )}
            </span>
        </span>
    )
}