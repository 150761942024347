import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { useApiQuery } from '@/api';
import PagesList from '@/components/PagesList/';
import { Container, Row, Column } from '@/components/Grid';
import { HeaderTop } from '@/components/Header';
import { LoaderContext } from '@/context/loaderState';
import styles from './Page.module.css';

export const Page = ({ title, body: __html }) => {
  const { t } = useTranslation(['main']);

  return (
    <HeaderTop top={<PagesList title={t('custom_service')} />} showFilter={true} showCartInfo={false}>
      <Container>
        <Row>
          <Column span={12}>
            <div className={classNames(styles.root, 'wysiwyg-content')}>
              <h1 children={title} className={styles.title} />
              <div dangerouslySetInnerHTML={{ __html }} />
            </div>
          </Column>
        </Row>
      </Container>
    </HeaderTop>
  )
}

export const PageContainer = () => {
  const { t } = useTranslation(['main']);
  const { toggleOverlay, toggleLoading } = useContext(LoaderContext);
  const { slug } = useParams();

  const { data } = useApiQuery({
    path: `/page/${slug}`,
    name: ['page', slug],
    onLoadStart: () => {
      toggleOverlay(true);
      toggleLoading(true);
    },
    onLoadEnd: () => {
      toggleOverlay(false);
      toggleLoading(false);
    }
  });

  return (
    data ? (
      <Page {...data} slug={slug} />
    ) : (
      <Page body={`<h1 style="text-align: center; font-size: 40px;">${t('404_not_found')}</h1>`} />
    )
  );
}
