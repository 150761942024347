import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ModalList } from '@/components/Modal';
import { scrollTo } from '@/components/AnchorsSmoothScroll';
import { langHref } from '@/components/Languages';
import styles from './PagesList.module.css';
import links from '@/data/links';

const PagesList = ({ page }) => {
  const { t } = useTranslation('main');
  const history = useHistory();
  const params = useParams();

  const slug = page ? page : params.slug;
  const { pages } = links;
  const divider = { name: 'divider' };

  const createOptions = (items) => {
    return Object.values(items)?.reduce((options, { id, url, visible, links }) => {
      if (visible) {
        if (links) {
          options = [...options, ...createOptions(links), divider];
        } else {
          options.push({
            value: id,
            name: t(id),
            isActive: pages[slug] && id === pages[slug].id,
            onClick: () => {
              if (/^#/.test(url)) scrollTo(url.substr(1), false);
              else history.push(langHref(url));
            }
          });
        }
      }
      return options;
    }, []);
  }

  const options = createOptions(pages);

  const control = ({ onClick }) => (
    <>
      {(slug !== null && pages[slug]) &&
        <span>
          <a onClick={onClick} href="/" className={styles.title}>{t('custom_service')} / </a>
          <a onClick={onClick} href="/">{t(pages[slug].id)}</a>
        </span>
      }
    </>
  )

  return (
    <ModalList
      control={control}
      items={[...options]}
    />
  );
}

export default PagesList;
