const size_guide = {
  xs: {
    waist: "23-34",
    chest: "32-33",
    hips:  "33-35",
    height: "5'4 - 5'8"
  },
  s: {
    waist: "23-34",
    chest: "32-33",
    hips:  "33-35",
    height: "5'4 - 5'8"
  },
  m: {
    waist: "26-28",
    chest: "35-37",
    hips:  "37-38",
    height: "5'4 - 5'8"
  },
  l: {
    waist: "28-30",
    chest: "37-39",
    hips:  "39-42",
    height: "5'8 - 6'0"
  },
  xl: {
    waist: "30-31",
    chest: "39-41",
    hips:  "42-44",
    height: "5'10 - 6'4"
  },
  xxl: {
    waist: "31-33",
    chest: "41-42",
    hips:  "44-45",
    height: "5'10 - 6'6"
  }
};

export default size_guide;
