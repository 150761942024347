import { keyBy } from './keyBy';

const googleAnalytics = (store) => {
 if (!window._ga) window._ga = {};

  if (store) {
    window._ga.lang = store.user.lang;
    window._ga.country = store.user.country;
    window._ga.currency = store.user.currency;

    const country = store.user.country;
    const course = (store.countries && store.countries[country]) ? store.countries[country].course : 1;

    if (store.products && store.products.length) {
      if (!window._ga.products) window._ga.products = {};

      Object.keys(store.products).map((id) => {
        const item = store.products[id];
        window._ga.products[item.slug] = {
          id: item.slug,
          style: {
            color: item.color,
            sizes: item.sizes[0].letter_sizes
          },
          name: item.style_id,
          brand: 'IDLE', // fixme
          price: (item.price['en'] * course).toFixed(0), // fixme
          categories: [item.collection]
        }

        return null;
      });
    }

    if (store.user.cart) {
      window._ga.cartItems = [];

      store.user.cart.map(({id, size}) => {
        if (store.products && store.products[id]) {
          const product = store.products[id];

          if (product) {
            const gaCartItem = window._ga.cartItems.find((item) => {
              return (item.id === product.slug && item.size === size);
            });

            if (!gaCartItem) {
              window._ga.cartItems.push({
                id: product.slug,
                name: product.style_id,
                size: size,
                price: (product.price['en'] * course).toFixed(0), //fixme
                qty: 1
              });
            } else {
              gaCartItem.qty = gaCartItem.qty + 1;
            }
          }
        }
        return null;
      });

      window._ga.cartTotal = store.user.cart.reduce((acc, { id }) => acc + keyBy(store.products, [id, 'price', 'en'], 0), 0);
      window._ga.cartTotal = (window._ga.cartTotal * course).toFixed(0);
      window._ga.cartQty = store.user.cart.length;
    }

    if (store.orderStatus && Object.values(store.orderStatus)) {
      window._ga.orderItems = [];
      window._ga.orderTotal = 0;

      Object.values(store.orderStatus).map(({order, orderId}) => {
        window._ga.orderId = orderId;

        order.products.map(({id, size, count, amount}) => {
          if (store.products && store.products[id]) {
            const product = store.products[id];

            if (product) {
              window._ga.orderItems.push({
                id: product.slug,
                name: product.style_id,
                size: size,
                price: (amount * course).toFixed(0) / count, //fixme
                qty: count
              });

              window._ga.orderTotal += (amount * course).toFixed(0) / count;
            }
          }

          return null;
        });

        return null;
      });
    }
  }
}

export const setViewProduct = (productId) => {
  window._ga.productId = productId;
}

export default googleAnalytics;