export const [ENABLE, SOLD, PREORDER, PREORDER_ALL] = ['E', 'S', 'P', 'A'];

const checkHasItems = (sizes) => {
    if (Object.keys(sizes).length > 0) {
        for (let size in sizes) {
            if (sizes[size] > 0) return true;
        }
    }

    return false;
}

export const getProductStatus = (sizes, preorder = false) => {
    let productStatus = SOLD;
    const hasItems = checkHasItems(sizes);

    if (hasItems && preorder) productStatus = PREORDER;
    else if (hasItems) productStatus = ENABLE;

    return productStatus;
}