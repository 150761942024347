import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './Input.module.css';
// import {InputError} from '@/components/Input/InputError';

export const Input = forwardRef(({ name, error, label, placeholder, value, onChange, type = 'text',
  pattern, maxLength, autoComplete, rootClass, inputClass, labelClass, required }, ref) => {
  const { t } = useTranslation();
  const errorText = t(`errors.${error}`);

  return (
    <label className={classNames(styles.root, rootClass)}>
      {/* <InputError error={error}> */}
      {(label || error) &&
        <span className={classNames(styles.label, labelClass, error && styles.labelError)}>
          {label}
          <span className={styles.labelErrorText}>{error && `(${errorText})`}</span>
        </span>
      }
      <input
        className={classNames(styles.input, inputClass, error && styles.inputError)}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
        pattern={pattern}
        maxLength={maxLength}
        autoComplete={autoComplete}
        required={required}
      />
      {/* </InputError> */}
    </label>
  )
});
