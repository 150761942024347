import {useDispatch, useSelector} from "react-redux";
import { Link } from '@/components/Languages/';
import {Trans, useTranslation} from "react-i18next";
import {selectItem} from "@/store/app/getters";
import {Button, mode} from "@/components/Button";
import {setItem} from "@/store/app/actions";
import styles from './Notice.module.css';
import links from '@/data/links';

const { pages } = links;

const path = ['user', 'notice'];

export const Notice = () => {
  const { t } = useTranslation('main');
  const dispatch = useDispatch();
  const is = useSelector(selectItem(path));

  if (is) return null;

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.text}>
          <Trans
            i18nKey="cookie_notice"
            components={{
              privacy_tag: <Link to={pages['privacy-and-cookie-policy'].url} />,
              terms_tag: <Link to={pages['terms-and-conditions'].url} />
             }}
          />
        </div>
        <div className={styles.btns}>
          <Button to={pages['privacy-and-cookie-policy'].url} className={styles.btn} mode={mode.inverse}>{t('read_more')}</Button>
          <Button onClick={() => dispatch(setItem(path, true))} className={styles.btn} mode={mode.dark}>{t('accept_continue')}</Button>
        </div>
      </div>
    </div>
  )
}
