import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { selectItem } from '@/store/app/getters';

import { setItem } from '@/store/app/actions';
import i18n from 'i18next';

const Languages = () => {
  const language = i18n.language;
  const dispatch = useDispatch();

  const countries = useSelector(selectItem(['countries'], {}));

  const updateLangs = (lang) => {
    if (lang) {
      dispatch(setItem(['user', 'lang'], lang));

      for (let country in countries) {
        if (countries[country].lang === lang) {
          dispatch(setItem(['user', 'country'], country));
        }
      }
    }
  };

  updateLangs(language);
  useEffect(() => {
    updateLangs(language);
    //eslint-disable-next-line
  }, [language]);

  return null;
}

export default Languages;