import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectItem } from '@/store/app/getters';
import { setItem } from '@/store/app/actions';

const def = {
  isPending: false,
  isSuccess: false,
  isFail: false,
};

export const rootPath = process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.host}/api`;
export const baseImgUrl = process.env.REACT_APP_MEDIA_URL;

export const useApiQuery = ({name = [], path, skip, mapper = (data) => data, onLoadStart, onLoadEnd}) => {
  const dispatch = useDispatch();

  const info = useSelector(selectItem(['api', path], { ...def }));
  const country = useSelector(selectItem(['user', 'country']));

  let url = new URL(rootPath + path);
  if (country) url.searchParams.set('country', country.toLowerCase());

  const { isPending, isSuccess, isFail } = info;
  const data = useSelector(selectItem(name));

  useEffect(() => {
    if (!(isSuccess || isPending || isFail || skip)) {
      if (onLoadStart) onLoadStart();

      dispatch(
        setItem(['api', path], Object.assign({}, info, { isPending: true }))
      );

      void fetch(url)
        .then((response) => {
          void response.json().then((result) => {
            dispatch(setItem(['api', path], Object.assign({}, info, { isPending: false, isSuccess: true })));
            dispatch(setItem(name, mapper(result, data)));
            if (onLoadEnd) onLoadEnd();
          }).catch((error) => {
            dispatch(setItem(['api', path], Object.assign({}, info, { isPending: false, isFail: true, error })));
            if (onLoadEnd) onLoadEnd();
          });
        })
        .catch((error) => {
          dispatch(setItem(['api', path], Object.assign({}, info, { isPending: false, isFail: true, error })));
        });
    }
    //eslint-disable-next-line
  }, [path]);

  return { isPending, isSuccess, isFail, data };
};
