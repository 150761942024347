import { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Input.module.css';

export const InputCountry = forwardRef(({ name, error, label, placeholder, value, onChange, type = 'text',
    pattern, maxLength, autoComplete, rootClass, inputClass, labelClass, }, ref) => {

    return (
        <label className={classNames(styles.root, rootClass)}>
            {(label) &&
                <span className={classNames(styles.label, labelClass)}>
                    {label}
                </span>
            }
            <select
                name={name}
                className={classNames(styles.input, inputClass, styles.countryInput)}
                value={value}
                onChange={onChange}
                ref={ref}
                hidden>
                <option selected value="US">United States</option>
            </select>
            {/* <input
                className={classNames(styles.input, inputClass, error && styles.inputError, styles.countryInput)}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
                placeholder={placeholder}
                pattern={pattern}
                maxLength={maxLength}
                autoComplete={autoComplete}
                disabled
            /> */}
            {/* </InputError> */}
        </label>
    )
});
