import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import styles from './Loader.module.css';

export const Loading = ({ className, showText = true }) => {
    const { t } = useTranslation('main');

    return (
        <span className={classNames(styles.loading, className)}>{showText && t('now_loading')}</span>
    );
}
