import { createSlice } from '@reduxjs/toolkit';
import { keyBy, setTo } from '@/helpers/keyBy';
import {mergeDeep} from "@/helpers/mergeDeep";

const initialState = {};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const [path, data, { isHard }] = action.payload;

      if (!path.length) return Object.assign(state, mergeDeep(state, data));

      const prev = keyBy(state, path);

      if (prev === data) return;

      const base = keyBy(state, path, data);
      const next = (isHard || typeof base !== 'object' || !base) ? data : Object.assign(Array.isArray(base) ? [] : {}, base, data)

      state = setTo(state, path, next);
    },
    pushItem: (state, action) => {
      const [path, data] = action.payload;

      setTo(state, path, (prev = []) => {
        const n = [...prev];
        n.push(data);
        return n;
      });
    },
    sliceItem: (state, action) => {
      const [path, i] = action.payload;

      setTo(state, path, (prev = []) => {
        const n = [...prev];
        n.splice(i, 1);
        return n;
      });
    },
    deleteItem: (state, action) => {
      const [path, key] = action.payload;

      setTo(state, path, (prev = {}) => {
        const n = {...prev};
        delete n[key];
        return n;
      });
    },
  },
});
