import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Price } from '@/components/Price';
import { selectItem } from '@/store/app/getters';
import { keyBy } from '@/helpers/keyBy';
import ruPlural from '@/helpers/ruPlural';
import styles from './CartTotal.module.css';
// import {sliceItem} from '@/store/app/actions';

const CartTotal = ({ className }) => {
  const { t } = useTranslation();
  const countries = useSelector(selectItem(['countries'], {}));
  const country = useSelector(selectItem(['user', 'country']));
  const cart = useSelector(selectItem(['user', 'cart'], []));
  const products = useSelector(selectItem(['products'], {}));

  const total = cart.reduce((acc, { id }) => acc + keyBy(products, [id, 'price', 'en'], 0), 0);
  const { description, cost } = countries[country];

  return (
    <div className={classNames(styles.root, className)}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <span className={styles.label}>{t('subtotal')}: </span>
          <span className={styles.value}>{t(ruPlural('items', cart.length), {n: cart.length})}, <Price value={total} /></span>
        </li>
        <li className={styles.item}>
          <span className={styles.label}>{t('estimated_shipping')}: </span>
          <span className={styles.value}>{description}{(cost && cost > 0) ? <>, <Price value={cost} /></> : null}</span>
        </li>
        <li className={styles.item}>
          <span className={styles.label}>{t('returnes')}: </span>
          <span className={styles.value}>{t('returnes_value')}</span>
        </li>
        <li className={styles.item}>
          <span className={styles.label}>{t('secure_payment')}: </span>
          <span className={styles.value}>{t('secure_payment_value')}</span>
        </li>
      </ul>
    </div>
  );
}

export default CartTotal;