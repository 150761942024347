import styles from './Input.module.css';

export const InputError = ({ error, children }) => {

  return (
   <>
     {children}
     {error && <span className={styles.error} children={error} />}
   </>
  )
}
