import classNames from 'classnames';
import { Container } from '@/components/Grid';
import Logo from '@/components/Logo'
import styles from './Header.module.css';

const Header = ({ left, right }) => {
  return (
    <Container className={classNames(styles.root, styles.up)} id="header">
      <div className={styles.side}>
        <Logo />
        {left}
      </div>
      <div className={styles.side} children={right} />
    </Container>
  );
};

export default Header;