import classNames from "classnames";
import styles from './Button.module.css';
import { Link } from '@/components/Languages/';
import {forwardRef} from "react";

export const mode = {
  link: 'link',
  main: 'main',
  primary: 'primary',
  secondary: 'secondary',
  inverse: 'inverse',
  dash: 'dash',
  none: 'none',
  dark: 'dark',
}

export const Button = forwardRef(({ className, full, to: toProps, disabled, mode: modeProps = mode.primary, ...props }, ref) => {
  let Tag = toProps ? Link : 'button';

  const to = toProps && toProps[0] === '#' ? { pathname: toProps } : toProps

  return <Tag ref={ref} className={classNames(styles.root, styles[modeProps], disabled && styles.disabled, full && styles.full, className)} to={to} {...props} />
})
