import { Route, Switch } from 'react-router';
import { MainQuery, CurrencyQuery } from './MainQuery';
import Loader from '@/components/Loader/Loader';
import { Header, HeaderCart } from '@/components/Header';
import { routes } from '@/data/routes';
import { Footer } from '@/components/Footer';
import { ScrollToTop } from '@/components/ScrollToTop/ScrollToTop';
import { useCache } from '@/components/Cache/useCache';
import LoaderState from '@/context/loaderState';
import styles from './Root.module.css';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectItem } from '@/store/app/getters';
import googleAnalytics from './helpers/googleAnalytics';

import { Languages } from './components/Languages';

export const Root = () => {
  useCache();

  const allStore = useSelector(selectItem());
  const country = useSelector(selectItem(['user', 'country']));
  window._ga = {};

  useEffect(() => {
    googleAnalytics(allStore);
  }, [allStore]);

  return (
    <>
      <Languages />
      <MainQuery />
      {country && country !== 'US' && <CurrencyQuery countryCode={country} />}
      <LoaderState>
        <Loader />
        <Header
          left={<div id='filters' className={styles.filters} />}
          right={<HeaderCart />}
        />
        <Switch>
          {routes.map(props => <Route key={props.path} {...props} />)}
        </Switch>
        <Footer />
        <ScrollToTop />
      </LoaderState>
    </>
  )
}
