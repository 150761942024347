import { Slide } from './Slide';

export const Slider = ({ items, active }) => {
  if (!items) return null;
  return (
    <>
      {items.map((item, i) => (
        <Slide
          key={i}
          {...item}
          current={item && item.name === active}
        />
      ))}
    </>
  );
};
