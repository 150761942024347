import { forwardRef, memo, useState } from "react";
import { useSelector } from "react-redux";
import LazyLoad from 'react-lazyload';
import { baseImgUrl } from "@/api";
import { videoType } from "@/components/Video/helper";
import { selectItem } from "@/store/app/getters";
import { LazyPlaceholder } from '@/components/LazyLoad';
import classNames from "classnames";
import styles from './Video.module.css';

export const Video = memo(forwardRef(
  ({ src, className, onClick, muted = true, autoPlay = true, preload = 'none', view, onLoad }, ref) => {
    return (
      <video
        key={src}
        className={classNames(styles.video, view === 'center' && styles.center, className)}
        muted={muted}
        preload={preload}
        autoPlay={autoPlay}
        controls={false}
        playsInline={true}
        onClick={onClick}
        ref={ref}
        onCanPlay={onLoad}
        loop
      >
        {src.map(link => <source key={link} src={baseImgUrl + link} type={`video/${videoType(link)}`} />)}
      </video>
    )
  }
))

Video.defaultProps = {
  src: [],
}

export const VideoContainer = forwardRef(({ src = {}, ...props }, ref) => {
  const size = useSelector(selectItem(['viewport']));
  const [isLoaded, setLoaded] = useState(null);

  return (
    <LazyLoad className={classNames(props.classNameWrapper, 'lazyload-wrapper--video')} placeholder={props.showLoader ? <LazyPlaceholder /> : ''}>
      {!isLoaded && props.showLoader && <LazyPlaceholder />}
      <Video {...props} src={src[size]} ref={ref} onLoad={() => setLoaded(true)} />
    </LazyLoad>
  );
})

export const AdaptorVideo = forwardRef(({ video = [], videoMobile = [], ...props }, ref) => {
  const src = {
    sm: [],
    md: [],
    lg: [],
  };

  let view;

  if (video.length !== videoMobile.length) videoMobile = video;

  for (let i = 0; i < video.length; i++) {
    src.sm.push(videoMobile[i].video)
    src.md.push(video[i].video)
    src.lg.push(video[i].video)
    view = video[i].view;
  }

  return <VideoContainer {...props} view={view} src={src} ref={ref} />
})
