import { useEffect } from 'react';
import { debounce } from '@/helpers/debounce';

export const scrollTo = (id, addHeaderOffset = false, behavior = 'smooth') => {
  if (!id) return;

  let offset = 0;

  if (addHeaderOffset) {
    const header = document.getElementById('header');
    if (header) offset += header.offsetTop + header.offsetHeight;
  }

  window.scroll({
    behavior: behavior,
    top: document.getElementById(id)?.offsetTop - offset,
  });
};

const handleClick = (id) => (e) => {
  if (!id) return;

  e.preventDefault();

  scrollTo(id, false);
};

const setAnchorPoints = () => {
  // if (isSSR) return;

  const links = document.querySelectorAll('a[href^=\\#]');

  for (const link of Array.from(links)) {
    const id = link.href.split('#')[1];

    link.onclick = handleClick(id);
  }
};

export const AnchorsSmoothScroll = () => {
  useEffect(() => {
    // if (isSSR) return;

    const action = debounce(300, setAnchorPoints);

    const mo = new MutationObserver(action);

    mo.observe(document.getElementById('root'), {
      childList: true,
      subtree: true,
    });

    return () => {
      mo.disconnect();
    };
  }, []);

  return null;
};
