import { useEffect, useMemo, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
// import { Link } from '@/components/Languages/';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Container } from '@/components/Grid';
import { Button, mode } from '@/components/Button';
import { sizes as all } from '@/data/sizes';
import { Products } from '@/components/Products/Products';
import { ProductInfo, ProductInfoButtons } from '@/components/Products/ProductInfo';
import SizeGuide from '@/components/Products/SizeGuide';
import { useApiQuery, baseImgUrl } from '@/api';
import { AdaptorVideo } from '@/components/Video/Video';
import { Delimiter } from '@/components/Delimiter';
import { SlideBody } from '@/components/Slider/SlideBody';
import { sizeParser } from '@/helpers/sizeParser';
import sortItems from '@/helpers/sortItems';
import { setItem, pushItem } from '@/store/app/actions';
import { selectItem } from '@/store/app/getters';
import { HeaderTop } from '@/components/Header';
import { Price } from '@/components/Price';
import { LazyImage } from '@/components/LazyLoad';
import { Notice } from '@/components/Notice/Notice';
import { LoaderContext } from '@/context/loaderState';
import { ENABLE, SOLD, PREORDER, PREORDER_ALL, getProductStatus } from '@/helpers/productStatus';
import { setViewProduct } from '@/helpers/googleAnalytics';
import ProductsFilter from '@/components/Products/ProductsFilter';
import { Social } from '@/components/Footer';

import styles from './Product.module.css';

const SIZE = 'Z';

export const Product = ({ id, title, style_id, preorder, sizes: sizesProps, similars, price, images, video_main_mobile, product_gallery_mobile,
  video_main, collection, info, washing_instructions, models, size_guide }) => {
  const { t } = useTranslation('main');
  const viewport = useSelector(selectItem(['viewport']));
  const dispatch = useDispatch();

  const [size, setSize] = useState(null);
  const [butState, setButState] = useState(getProductStatus(sizesProps, preorder));
  const [infoState, setInfoState] = useState(null);
  // const { name: collectionTitle } = useSelector(selectItem(['collections', collection], {}));

  useEffect(() => {
    setSize(null);
  }, [id]);

  const sizes = useMemo(() => (
    <>
      {all.map((s, index) => {
        const disable = !(sizesProps[s]);
        return (
          <div
            key={index}
            onClick={() => !disable && setSize(s)}
            className={classNames(styles.size, size === s && styles.sizeActive, disable && styles.sizeDisable)}
            children={s}
          />
        )
      })}
      {size && setButState(ENABLE)}
    </>
  ), [size, sizesProps]);

  let butText,
    butClass;

  if (butState === SOLD) { butText = `[ ${t('sold_out')} ]`; butClass = 'ga-pg-btn-sold'; }
  else if (butState === PREORDER_ALL) { butText = `[ ${t('preorder')} ]`; butClass = 'ga-pg-btn-preorder'; }
  else if (butState === PREORDER) { butText = t('preorder'); butClass = 'ga-pg-btn-preorder'; }
  else if (butState === SIZE) { butText = t('select_size'); butClass = 'ga-pg-btn-selectsize'; }
  else { butText = t('add_to_cart'); butClass = 'ga-pg-btn-addtocart'; }

  const onAdd = () => {
    if (size) {
      dispatch(pushItem(['user', 'cart'], { id, size }));
      dispatch(setItem(['statuses', 'isCartOpen'], true));
      setSize(null);
    } else {
      setButState(SIZE)
    }
  }

  return (
    <>
      <HeaderTop
        top={<ProductsFilter crumbs={[title]} collectionId={collection} />}
        // top={<span> <Link children={collectionTitle} to={`/?collection=${collection}#products`} /> / {title}</span>}
        showFilter={viewport !== 'sm'}
      >
        <div className={styles.root}>
          <div className={styles.main}>
            <div className={styles.mainVideo}>
              <AdaptorVideo video={video_main} videoMobile={video_main_mobile} className={styles.video} showLoader={true} />
            </div>
            <div className={styles.mainImages}>
              <AdaptorImages images={images} images_mobile={product_gallery_mobile} alt={title} />
            </div>
          </div>
        </div>
        <Container className={styles.footer}>
          <ProductInfoButtons
            guide={[size_guide, <SizeGuide />]}
            info={[washing_instructions, models, info]}
            onClickButton={setInfoState}
            current={infoState}
          />
          {/* <ProductModals guide={size_guide} info={[washing_instructions, <br />, <br />, models, <br />, <br />, info]} /> */}
          <div className={classNames(styles.info, 'ga-pg-product-info')}>
            <div className={styles.block}>
              <h3 className={styles.title}>{style_id ? style_id : title}</h3>
              <span className={styles.price}><Price value={price} /></span>
              <div className={styles.sizes} children={sizes} />
            </div>
            <Button
              mode={mode.main}
              className={classNames(styles.buy, butClass)}
              disabled={butState === SOLD || butState === PREORDER_ALL || butState === SIZE}
              children={butText}
              onClick={onAdd}
            />
          </div>
        </Container>
      </HeaderTop>
      <ProductInfo
        guide={[
          { title: t('size_guide'), content: <SizeGuide />, span: 12 },
          { title: t('models_info'), content: size_guide, span: 12 }
        ]}
        info={[
          { title: t('washing_instructions'), content: washing_instructions, span: 6 },
          { title: t('product_info'), content: info, span: 6 }
        ]}
        current={infoState}
      />
      <RelatedSeries collection={collection} />
      <RelatedProducts items={similars} />
      <Notice />
      <Social />
    </>
  );
}

Product.defaultProps = {
  sizes: [],
}

const AdaptorImages = ({ images, images_mobile, alt = '' }) => {
  const viewport = useSelector(selectItem(['viewport']));

  return (viewport === 'sm' ? images_mobile : images)?.map(({ image }, index) => {
    return <LazyImage key={image + index} src={baseImgUrl + image} className={styles.img} alt={alt} />
  });
}

const RelatedSeries = ({ collection }) => {
  const { t } = useTranslation('main');
  const collections = useSelector(selectItem(['collections'], {}));

  const item = collections && collections[collection];

  if (!(item && item.video && item.video.length > 0)) return null;

  return (
    <>
      <Delimiter title={`${t('related_series')} [${item.name[0]}]`} />
      <div className={styles.relative}>
        <AdaptorVideo {...item} className={styles.video} showLoader={true} />
        <SlideBody title={item.title} className={classNames(styles.videoBody, styles.productVideoContainer)} name={item.name} />
      </div>
    </>
  );
}

const RelatedProducts = ({ items }) => {
  const { t } = useTranslation('main');
  const history = useHistory();

  const { search } = useLocation();
  const sort = new URLSearchParams(search).get('sort_by');

  if (!items?.length) return null;

  const onAll = () => {
    history.push('/#products')
  };

  return (
    <>
      <Delimiter title={`${t('related_products')} [${items.length}]`} />
      <Products items={sortItems(items, sort)} className={'ga-pg-products-related'} listId="2" listName="relative" onAll={onAll} />
    </>
  );
}

export const ProductContainer = () => {
  const { toggleOverlay, toggleLoading } = useContext(LoaderContext);

  const { slug } = useParams();
  const currency = 'en' // useSelector(selectItem(['user', 'lang']));
  const data = useSelector(selectItem(['products', slug]));

  useEffect(() => {
    if (!data) {
      toggleOverlay(true);
      toggleLoading(true);
    } else {
      toggleOverlay(false);
      toggleLoading(false);

      setViewProduct(data.slug);
    }

    return () => { setViewProduct(null) };
    //eslint-disable-next-line
  }, [data]);

  useApiQuery({
    name: [],
    path: `/products/${slug}`,
    skip: !!data?.similars,
    mapper: (data, prev) => {
      const products = { [data.id]: data, [data.slug]: data };

      return { products: Object.assign({}, prev.products, products) }
    }
  });

  if (!data) {
    // toggleOverlay(true);
    toggleLoading(true);
    return null;
  }

  return <Product {...data} sizes={sizeParser(data.sizes)} price={data.price[currency]} />
}
