import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './Footer.module.css'
import { CountryStatic } from '../Country/CountryStatic';
import Link from '../../components/Languages/LangLink';
import links from '@/data/links';

const { pages, social } = links;

const Footer = () => {
  const { t } = useTranslation('main');

  if (!links) return null;

  return (
    <>
      <div className={styles.down}>
        <div className={styles.downP}>
          <CountryStatic label={t('country')} className={styles.downLink} />
          {Object.values(pages)?.map(({ id, url, visible }, i) => (
            visible &&
            <Link
              key={url}
              className={classnames(styles.downLink, styles.downLinkDash)}
              to={url}
              children={t(id)}
            />
          ))}
        </div>
      </div>
      <div className={styles.cc}>
        {t('footer_copyright')} <a href={`mailto:${social.service_email.url}`}>{social.service_email.url}</a>
      </div>
    </>
  );
}

export default Footer;
