import { useEffect, useRef, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import { isMobile } from 'mobile-device-detect';
import { useTranslation } from 'react-i18next';
// import {Video} from '@/components/Video';
import { Button, mode } from '@/components/Button';
import { Container } from '@/components/Grid';
import classNames from 'classnames';
import styles from './Slider.module.css';
import { SlideBody } from '@/components/Slider/SlideBody';
import { AdaptorVideo } from '@/components/Video/Video';
import { LoaderContext } from '@/context/loaderState';
import Modal from '../Modal/Modal';


export const Slide = ({ id, name, title, video, videoMobile, story, current }) => {
  const { t } = useTranslation('main');
  const ref = useRef(null);
  const timeoutRef = useRef(null);
  const titleRef = useRef(null);
  const videoRef = useRef(null);

  const [isFullVideoShow, setFullVideoShow] = useState(false);
  const [showVideo, toggleVideo] = useState(false);
  const { isShowOverlay, toggleOverlay, isLoading, toggleLoading } = useContext(LoaderContext);

  useEffect(() => {
    const onScroll = () => {
      if (!ref.current || !titleRef.current) return;

      const { y, height } = ref.current.getBoundingClientRect();

      titleRef.current.style.top = `${height / 2 + 10 + (y / 3)}px`;
      // titleRef.current.style.width = `${width}px`;
    };

    const onResize = () => {
      if (!videoRef.current || !isMobile) return;
      videoRef.current.style.height = window.screen.availHeight + 'px';
    };

    toggleVideo(false);
    if (current === undefined) toggleLoading(true);

    if (current) {
      onScroll();
      onResize();

      window.addEventListener('scroll', onScroll);
      window.addEventListener('resize', onScroll);
      window.addEventListener('resize', onResize);

      toggleLoading(true);

      timeoutRef.current = setTimeout(() => {
        toggleVideo(current);
        toggleLoading(false);
        isShowOverlay && toggleOverlay(false);
      }, 1000);

      return () => {
        window.removeEventListener('scroll', onScroll);
        window.removeEventListener('resize', onScroll);
        window.removeEventListener('resize', onResize);
        clearTimeout(timeoutRef.current)
        if (!isShowOverlay) {
          toggleLoading(false);
        }
      }
    } else {
      setFullVideoShow(false);
    }

    //eslint-disable-next-line
  }, [current])

  const targetV = document.getElementById('explore_full_story');
  const targetF = document.getElementById('filters');

  const storyVideos = story ? story.map(item => ({ ...item, view: 'center' })) : null;

  const fullVideo = targetV && isFullVideoShow && createPortal((
    <div className={styles.fullVideoContainer}>
      <AdaptorVideo
        className={styles.fullVideo}
        video={storyVideos ? storyVideos : video}
        videoMobile={storyVideos ? storyVideos : videoMobile}
        onClick={() => { setFullVideoShow(false); toggleLoading(false); }}
        muted={false}
      />
    </div>
  ), targetV);

  const btn = targetF && createPortal(<span />, targetF);

  return (
    <div id={name} className={classNames(styles.root, current && styles.visible)} ref={ref}>
      <div className={classNames(styles.video, showVideo && styles.videoVisible)}>
        <AdaptorVideo
          video={video}
          videoMobile={videoMobile}
          autoPlay={true}
          ref={videoRef}
        />
      </div>
      <SlideBody name={name} title={title} ref={titleRef} className={current && styles.contentVisible} />
      {current && (
        <Container className={classNames(styles.bottom)}>
          {!isLoading &&
            <Button
              mode={mode.dash}
              onClick={() => { setFullVideoShow(true); toggleLoading(true); }}
            >{t('explore_full_story')}</Button>
          }
          {fullVideo}
          {btn}
        </Container>
      )}
      <Modal open={false} />
    </div>
  );
}
