import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, mode} from '@/components/Button';
import { scrollTo } from '@/components/AnchorsSmoothScroll';
import { Container, Row, Column } from '@/components/Grid';

import styles from './ProductModals.module.css';

export const ProductInfo = (props) => {
  console.log(props)
  return (
    <div id={'product_info'}>
      {props.current ? (
        <Container className={classNames(styles.content, 'wysiwyg-content', `ga-pg-view-${props.current}`)}>
          <Row>
          {props[props.current].map(({ title, content, span }, index) => (
            <Column span={span}>
              {title && <h3>{title}</h3>}
              {content}
            </Column>
          ))}
          </Row>
        </Container>
      ) : null}
    </div>
  )
};

export const ProductInfoButtons = (props) => {
  const { t } = useTranslation('main');

  let btns = [];
  if (props.guide) btns.push({ name: 'guide', title: t('size_guide') });
  if (props.info) btns.push({ name: 'info', title: t('product_info') });

  const onTrigger = (value) => {
    const next = value === props.current ? null : value;
    props.onClickButton(next);
    if (next) scrollTo('product_info', false);
  };

  return (
    <div className={styles.btns}>
      {btns && btns.map(({ name, title }, index) => (
        <Button
          key={index}
          mode={mode.dash}
          onClick={() => onTrigger(name)}
          className={classNames(styles.link, props.current === name && styles.linkActive, `ga-pg-btn-${name}`)}
          children={title}
        />
      ))}
    </div>
  )
};
