import logo from './logo.svg';
import cart from './cart.svg';
import close from './close.svg';
import checkmark from './checkmark.svg';
import cards from './cards.svg';
import demotivated from './demotivated.svg';
import arrow from './arrow.svg';
import applePayIcon from './applePayIcon.svg'

const objects = { logo, cart, cards, demotivated, close, checkmark, arrow, applePayIcon };

export default objects;
