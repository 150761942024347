import { useTranslation } from 'react-i18next';
import { Column, Container, Row } from '@/components/Grid';
import styles from './Footer.module.css'
import links from '@/data/links';

const { social } = links;

const Social = () => {
  const { t } = useTranslation('main');

  let ticks = [];
  for (let i = 0; i < 30; i++) {
    ticks.push('');
  }

  if (!links) return null;

  return (
    <>
      <div className={styles.ticker}>
        {ticks.map((item, index) => <div key={index} className={styles.tickerItem}>{t('stay_in_touch')} &nbsp;&nbsp;</div>)}
      </div>
      <div className={styles.body}>
        <Container>
          <Row>
            <Column span={12} className={styles.socialLinks}>
              {Object.values(social)?.map(({ id, url, visible }, i) => (
                visible &&
                <div className={styles.socialLinksItem} key={id + i}>
                  {url ? <a href={url} className={styles.socialLinksLink}>{t(id)}</a> : <span className={styles.socialLinksText}>{t(id)}</span>}
                </div>
              ))}
            </Column>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Social;
