import {useApiQuery} from '@/api';

export const CurrencyQuery = ({ countryCode }) => {
  useApiQuery({
    name: ['countries', countryCode],
    path: `/currency?country=${countryCode.toLowerCase()}&amount=1`,
    mapper: (data, prev) => {
      return Object.assign({}, prev, {course: data.currency});
    },
  });

  return null;
}

export const MainQuery = () => {
  useApiQuery({
    name: [],
    path: '/main',
    mapper: (data, prev) => {
      let next = {}
      for (const key in data) next[key] = Object.assign(prev[key] || {}, data[key]);
      return next
    },
  });

  return null;
};
