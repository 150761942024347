import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from '@/components/Languages/';
import { ReactSVG } from 'react-svg';
import icons from '@/assets/icons';
import { setItem } from '@/store/app/actions';
import styles from './Logo.module.css';

const Logo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const LogoWrap = location.pathname === '/' ? 'a' : Link;

  const handleClickLogo = () => {
    dispatch(setItem(['statuses', 'isCartOpen'], false));
    // if (isCartOpen) dispatch(setItem(['statuses', 'isCartOpen'], false));
  };

  return (
    <>
        <LogoWrap to="/" href="/" onClick={() => handleClickLogo()}>
          <ReactSVG src={icons.logo} className={styles.image} />
        </LogoWrap>
    </>
  );
};

export default Logo;
