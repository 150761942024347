import { Link } from 'react-router-dom';
import i18n from 'i18next';

const LangLink = (props) => {
  const lang = i18n.language;
  const link = lang !== 'en' ? `/${lang}${props.to}` : props.to;

  return (
    <Link {...props} to={link} />
  )
}

export default LangLink;