import { useTranslation, Trans } from 'react-i18next';
import { Link, langHref } from '@/components/Languages';
import OrderInfoTable from './OrderInfoTable';
import OrderTotalTable from './OrderTotalTable';
import { Button, mode } from '@/components/Button';
import links from '@/data/links';

import styles from './OrderInfo.module.css';

const { pages, social } = links;

const OrderInfo = ({ order, onClearOrderId }) => {
  const { t } = useTranslation(['main']);
  const { orderId, status, delivery, products } = order;

  return (
    <div className="wysiwyg-content">
      <h3 className={styles.subtitle}>{t('order_details')}</h3>

      <div className={styles.tableWrapper}>
        <OrderInfoTable id={orderId} delivery={delivery} />
      </div>
      <p>
        <Trans
          i18nKey="looking_for_antoher_order"
          components={{
            // eslint-disable-next-line
            tag: <a href={langHref(pages['order-status'].url)} onClick={() => onClearOrderId()} />,
          }}
        />
      </p>

      <div>
        {t('if_you_see_any_mistakes')} <a className="underline" href={`mailto:${social.service_email.url}`}>{social.service_email.url}</a>
      </div>

      <hr className={styles.divider} />

      <h3 className={styles.subtitle}>{t('order_summary')}</h3>

      <OrderTotalTable products={products} delivery={delivery} />

      <h3 className={styles.subtitle}>{t('cancellations_and_returnes')}</h3>

      <p>
        <Trans
          i18nKey="changed_your_mind"
          components={{
            cancel_tag: <Link className="underline" to={pages['customer-service'].url + pages['customer-service'] + '#cancellations'} />,
            service_tag: <Link className="underline" to={pages['customer-service'].url} />,
          }}
        />
      </p>

      <div className={styles.btnContainer}>
        {/* <Button mode={mode.secondary} to={`${pages['order-cancel'].url}?orderId=${orderId}`} className={styles.btn}>
          {t('cancel_order')}
        </Button> */}
        <Button mode={mode.primary} to={`${pages['order-return'].url}?orderId=${orderId}`} className={styles.btn} disabled={status !== 'Delivered'}>
          {t('start_return')}
        </Button>
      </div>

      <h1 className={styles.title}>{t('order_status_timeline')}</h1>

      <div>
        <div className={styles.date}>
          <b>{'' + new Date()}</b>
        </div>
        <b className={styles.status}>
          {t(status)}
          {/* {status && t('your_order_no', { order_number: orderId, order_status: status })} */}
        </b>
        <div className={styles.text}>
          {t('please_wait_order_confirmation')} <a href={langHref(pages['order-status'].url)}>https://idlecorp.com{pages['order-status'].url}</a>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;