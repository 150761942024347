import { useLocation } from "react-router";
import { useEffect, useContext } from "react";
import { LoaderContext } from '@/context/loaderState';

export const ScrollToTop = () => {
  const { pathname, hash, key } = useLocation();
  const { isShowOverlay } = useContext(LoaderContext);

  useEffect(() => {
    window && window.scroll({
      top: 0,
    });
  }, [pathname])

  useEffect(() => {
    const elem = document.getElementById(hash.substring(1));
    let offsetTop = elem ? elem.offsetTop : 0;

    window && offsetTop && window.scroll({
      top: offsetTop,
    });
  }, [hash, key, isShowOverlay])

  return null;
}
