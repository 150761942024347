import { useTranslation } from 'react-i18next';
import size_guide from '@/data/size_guide';

const SizeGuide = () => {
  const { t } = useTranslation();

  return (
    <div className="table-wrapper">
    <table className="table-hover">
    <tbody>
      <tr>
        <th className="text-uppercase">{t('size')}</th>
        {Object.keys(size_guide[Object.keys(size_guide)[0]]).map(key => (
          <th className="text-uppercase">{t(key)}</th>
        ))}
      </tr>
      {Object.keys(size_guide).map(key => (
        <tr>
          <td className="table-td-title text-uppercase">{key}</td>
          {Object.values(size_guide[key]).map(value => (
            <td>{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
    </table>
    </div>
  );
};

export default SizeGuide;