import { forwardRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import styles from './Modal.module.css';

const setDocumentOverflow = ( hide ) => {
  if (hide) {
    const top = document.documentElement.scrollTop;
    document.documentElement.style = `overflow: hidden; position: fixed; top: ${-top}px;`;
    document.documentElement.dataset.scrollTop = top;
  } else {
    const top = document.documentElement.dataset.scrollTop;
    document.documentElement.style = `overflow: hidden auto; position: static; top: 0;`;
    document.documentElement.scrollTop = top;
    document.documentElement.dataset.scrollTop = 0;
  }
}

const Modal = forwardRef(
  ({
    children,
    open: openProps = false,
    side = 'left',
    classNameWrapper,
    className,
    classNameContent,
    overlay,
    control,
    onClose,
    stopScroll = true,
    target: targetProps
  }, ref) => {
    let location = useLocation();
    const { pathname } = location;

    const [prevLocation, setPrevLocation] = useState(pathname);
    const [prevOpen, setPrevOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const target = targetProps?.current || document.getElementById('modal');

    useEffect(() => {
      if (prevLocation === pathname) return null;
      setPrevLocation(pathname);
      setDocumentOverflow(false);

      // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
      if (openProps !== open) setOpen(openProps);
      // eslint-disable-next-line
    }, [openProps]);

    useEffect(() => {
      if (prevOpen === open || !stopScroll) return null;
      setDocumentOverflow(open);
      setPrevOpen(open);
      //eslint-disable-next-line
    }, [open]);

    return (
      <>
        {open && target && createPortal(
          <div className={classNames(styles.root, styles[side], classNameWrapper)} ref={ref}>
            <div
              className={classNames(styles.overlay, (overlay && overlay.class) && styles[overlay.class])}
              style={(overlay && overlay.background) ? {backgroundImage: `url(${overlay.background})`} : {}}
              onClick={onClose}
            >
              {(overlay && overlay.content) &&
                <div className={classNames(styles.overlayContent)}>{overlay.content}</div>
              }
            </div>
            {children ? (
              <div className={classNames(styles.contentWrapper, className)}>
                <div className={classNames(styles.content, classNameContent)} children={children} />
              </div>
             ) : null}
          </div>, target)
        }
        {control && control(isOpen => setOpen(isOpen || !open))}
      </>
    );
  }
)

export default Modal;