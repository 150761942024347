import {useTranslation} from 'react-i18next';
import styles from './OrderTables.module.css';

const OrderInfoTable = ({id, delivery}) => {
  const { t } = useTranslation(['main']);

  if (!id || !delivery) return null;

  return (
    <div className="table-wrapper">
    <table>
      <tbody>
        <tr>
            <td width="27%" className={styles.head}>{t('order_number')}</td>
            <td>{id}</td>
        </tr>
        <tr>
            <td className={styles.head}>{t('order_date_time')}</td>
            <td>{delivery.date}</td>
        </tr>
        <tr>
            <td className={styles.head}>{t('name')}</td>
            <td>{delivery.name}</td>
        </tr>
        <tr>
            <td className={styles.head}>{t('shipping_address')}</td>
            <td>{delivery.shipping_address}</td>
        </tr>
        {delivery.billing_address &&
          <tr>
              <td className={styles.head}>{t('billing_address')}</td>
              <td>{delivery.billing_address}</td>
          </tr>
        }
        <tr>
            <td className={styles.head}>{t('phone')}</td>
            <td>{delivery.phone}</td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default OrderInfoTable;
