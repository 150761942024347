
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectItem } from '@/store/app/getters';
import { setItem } from '@/store/app/actions';

const HeaderTop = ({ top, children, showFilter = true, showCartInfo = true }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isFilterVisible, toggleFiltersVisible] = useState(false);
  const isCartOpen = useSelector(selectItem(['statuses', 'isCartOpen']));
  const winWidth = window.innerWidth;

  useEffect(() => {
    if (showFilter && showCartInfo) {
      if (ref.current) {
        const elem = ref.current;
        const observer = new IntersectionObserver(
          ([{ isIntersecting }]) => {
            toggleFiltersVisible(isIntersecting);
            dispatch(setItem(['statuses', 'isShowText'], !isIntersecting));
          },
          { threshold: [0.05] }
        );

        observer.observe(elem);

        return () => {
          elem && observer.unobserve(elem);
        }
      }
    } else if (!showFilter && showCartInfo) {
      toggleFiltersVisible(false);
      dispatch(setItem(['statuses', 'isShowText'], true));
    } else {
      toggleFiltersVisible(true);
      dispatch(setItem(['statuses', 'isShowText'], false));
    }

    // eslint-disable-next-line
  }, [ref, showFilter, showCartInfo, isCartOpen]);

  useEffect(() => {
    if (winWidth < 1536 && isCartOpen && isFilterVisible) toggleFiltersVisible(false);

    // eslint-disable-next-line
  }, [isFilterVisible, isCartOpen]);

  const targetF = document.getElementById('filters');

  return (
    <>
      {isFilterVisible && targetF && createPortal(top, targetF)}
      <div ref={ref} children={children}/>
    </>
  )
};

export default HeaderTop;
