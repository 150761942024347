import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { keyBy } from '@/helpers/keyBy';
import { Input } from '@/components/Input/Input';
import { Button, mode } from '@/components/Button';

import styles from './OrderForm.module.css';

const createSchema = (inputs) => {
  let schema = {};

  inputs.map(({name}) => {
    if (name === 'email') {
      schema[name] = yup.string().email('invalid_email').required('required_err');
    } else if (name === 'phone') {
      schema[name] = yup.string().matches(/[0-9 +()]/g, 'invalid_phone').required('required_err');
    } else {
      schema[name] = yup.string().required('required_err');
    }

    return null;
  });

  return schema;
}

const OrderForm = ({ onSubmit }) => {
  const { t } = useTranslation(['main']);

  const inputs = [
    { name: 'orderId', component: Input, props: { label: t('order_no'), placeholder: t('type_here') } },
    { name: 'email', component: Input, props: { label: t('your_email'), placeholder: t('type_here') } }
  ];

  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: async data => {
      try {
        const validationSchema = yup.object(createSchema(inputs));
        const values = await validationSchema.validate(data, { abortEarly: false });

        return { values, errors: {} };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.searchForm}>
        {inputs.map(({ name, props, component: Component }, index) => (
          <Component
            {...props}
            {...register(name)}
            inputClass={styles.searchInput}
            labelClass = {styles.searchInputLabel}
            rootClass = {styles.searchInputRoot}
            key={index}
            error={t(keyBy(errors, [name, 'message'], null))}
          />
        ))}
        <div className={styles.btnContainer}>
          <Button className={styles.btn} mode={mode.primary}>{t('check')}</Button>
        </div>
      </form>
    </>
  );
};

export default OrderForm;