import classNames from 'classnames';
import styles from './Column.module.css';

export const Column = ({ span, sm, md, lg, className, ...other }) => {
  const sizes = { sm, md, lg };

  const classNamesColumn = Object.keys(sizes).reduce((acc, size) => {
    const value = sizes[size];

    if (Number.isInteger(value)) acc.push(styles[`col-${size}-${value}`]);

    return acc;
  }, []);

  if (Number.isInteger(span)) classNamesColumn.push(styles[`col-${span}`]);

  return (
    <div className={classNames(className, classNamesColumn)} {...other} />
  );
}
