import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApiQuery } from '@/api';
import { ProductContainer as Product } from './Product';
import { Container, Row, Column } from '@/components/Grid';
import { Button } from '@/components/Button';
import { selectItem } from '@/store/app/getters';
import { setTo } from '@/helpers/keyBy';
import sortItems from '@/helpers/sortItems';
import { scrollTo } from '@/components/AnchorsSmoothScroll';

import styles from './Products.module.css';

export const Products = ({ id, items, onMore, onAll, className, listId, listName }) => {
  const { t } = useTranslation('main');
  const isCartOpen = useSelector(selectItem(['statuses', 'isCartOpen']));
  const column = {
    span: isCartOpen ? 6 : 4,
    lg: 6,
    md: 6,
    sm: 12,
  };

  return (
    <Container id={id} className={className} data-list-id={listId} data-list-name={listName}>
      <Row className={styles.base}>
        {items.map((item, i) => (
          <Column {...column} key={i}>
            <Product {...item} listPosition={i+1} />
          </Column>
        ))}
      </Row>
      {onMore && (
        <Row>
          <Column span={12}>
            <Button className={classNames(styles.more, 'ga-more')} onClick={onMore}>{t('show_more')}</Button>
          </Column>
        </Row>
      )}
      {onAll && (
        <Row>
          <Column span={12}>
            <Button className={classNames(styles.more, 'ga-all')} onClick={onAll}>{t('view_all_products')}</Button>
          </Column>
        </Row>
      )}
    </Container>
  )
}

const path = '/products?';

export const ProductsContainer = props => {
  const id = props.collectionId;
  const [page, setPage] = useState(1);
  const history = useHistory();

  const limit = props.collection ? 48 : 24;
  const products = useSelector(selectItem(['products'], {}));
  const { data = {}, items = [] } = useSelector(selectItem(['collections', '' + id], { }));

  const { search } = useLocation();
  const sort = new URLSearchParams(search).get('sort_by');

  useEffect(() => {
    setPage(1);
  }, [props.collection]);

  const params = {
    page,
    offset: limit * (page - 1),
    limit,
    category: props.collection,
  };

  useApiQuery({
    name: [],
    // TODO: helper
    path: path + Object.keys(params).filter(k => !!params[k]).map(k => `${k}=${params[k]}`).join('&'),
    skip: !!items[page],
    mapper: ({ data, items = [] }, prev) => {
      let next = ['collections', 'products'].reduce((a, c) => ({ ...a, [c]: prev[c] }), {});

      // TODO: helper
      const products = items.reduce((a, c) => ({ ...a, [c.slug]: c }), {});

      setTo(next, ['products'], (p = {}) => Object.assign(p, products));
      setTo(next, ['collections', id, 'data'], data);
      setTo(next, ['collections', id, 'items'], (p = []) => Object.assign(p, { [page]: Object.keys(products) }));

      return next;
    }
  });

  const _items = sortItems(items.reduce((a, c) => [...a, ...c], []).map(id => products[id]), sort);

  const onMore = (data.total > _items.length) ? (
      () => { setPage(page + 1) }
    ) : null;

  const onAll = params.category ? (
      () => { history.push('?'); scrollTo('products', false); }
    ) : null;

  return <Products {...props} items={_items} onMore={onMore} onAll={onAll} />
}
