import { useSelector } from 'react-redux';
import { selectItem } from '@/store/app/getters';

const Currency = () => {
    const countries = useSelector(selectItem(['countries'], {}));
    const country = useSelector(selectItem(['user', 'country']));

    const currecy = (countries[country] && countries[country].currency) ? countries[country].currency : 'usd';
    if (currecy === 'rub') return '₽';
    else return currecy;
}

export default Currency;
