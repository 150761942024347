const [POPULAR_ASC, POPULAR_DESC, PRICE_ASC, PRICE_DESC] = ['popular_asc', 'popular_desc', 'price_asc', 'price_desc'];

const sortItems = (items, sorting) => {
  if (!items) return null;

  if (sorting === POPULAR_DESC) {
    let sortedItems = items.slice().reverse();
    return sortedItems;
  } else if (sorting === PRICE_ASC) {
    let sortedItems = items.slice().sort((a, b) => (a.price['en'] - b.price['en']));
    return sortedItems;
  } else if (sorting === PRICE_DESC) {
    let sortedItems = items.slice().sort((a, b) => (b.price['en'] - a.price['en']));
    return sortedItems;
  } else {
    return items;
  }
}

export const sortings = [POPULAR_ASC, POPULAR_DESC, PRICE_ASC, PRICE_DESC];

export default sortItems;