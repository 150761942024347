const ruPlural = function(k, n){
  let postfix = '_other';

  const a = (n % 10 === 1);
  const b = (n % 100 !== 11);
  if (a && b) postfix = '_one';

  const c = [2, 3, 4].indexOf(n % 10)     !== -1;
  const d = [12, 13, 14].indexOf(n % 100) === -1;
  if (c && d) postfix = '_few';

  const e = n % 10 === 0;
  const f = [5, 6, 7, 8, 9].indexOf(n % 10)   !== -1;
  const g = [11, 12, 13, 14].indexOf(n % 100) !== -1;
  if (e || f || g) postfix = '_many';

  return k + postfix;
}

export default ruPlural;
