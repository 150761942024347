import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectItem} from "@/store/app/getters";
import {setItem} from "@/store/app/actions";

const path = ['user'];

export const useCache = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectItem(path, {}));

  useEffect(() => {
    const cache = localStorage?.getItem('user');

    // console.log(cache)

    if (!cache) return;

    try {
      dispatch(setItem(path, JSON.parse(cache) || {}))
    } catch (e) {
      console.warn(e)
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => { localStorage.setItem('user', JSON.stringify(user)) }, [user])
}
