import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { baseImgUrl } from '@/api';
import { keyBy } from '@/helpers/keyBy';
import { Link } from '@/components/Languages/';
import { setItem } from '@/store/app/actions';
import { selectItem } from '@/store/app/getters';
import { LazyImage } from '@/components/LazyLoad';
import { Price } from '@/components/Price';
import icons from '@/assets/icons';
import styles from './Item.module.css';

const CartProduct = ({ style_id, title, slug, cart_image, price, size, onDelete }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.root, 'ga-cart-product')} data-product-id={slug}>
      <Link to={`/${slug}`} className={styles.link} onClick={() => { dispatch(setItem(['statuses', 'isCartOpen'], false)); }}></Link>
      <LazyImage classNameWrapper={styles.imgWrapper} className={styles.img} src={baseImgUrl + cart_image} alt={title} />
      <div className={styles.body}>
        <div className={styles.title}>{style_id ? style_id : title}</div>
        <span className={styles.feature}>{t('size')}: <b>{size}</b></span>
        <span className={styles.feature}>{t('price')}: <b><Price value={price} /></b></span>
      </div>
      <div className={classNames(styles.remove, 'ga-cart-bnt-remove')} data-product-id={slug} src={icons.close} onClick={() => onDelete()}>
        <ReactSVG data-product-id={slug} src={icons.close} />
      </div>
    </div>
  );
}

const CartItem = ({ id, size, onDelete, }) => {
  const data = useSelector(selectItem(['products', id]))

  return data && (
    <CartProduct
      {...data}
      price={keyBy(data, ['price', 'en'], 0)}
      size={size}
      onDelete={onDelete}
    />
  )
}

export default CartItem;
