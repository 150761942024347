const CartIcon = ({ filling = 0, className }) => {
    const fillingOffset = 30 - (filling * 30 / 100);

    return (
        <svg width="100%" height="100%" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <defs>
                <mask id="mask" x="0" y="0" width="36" height="30">
                    <rect x="0" y={fillingOffset} fill="#fff" width="36" height="30"></rect>
                </mask>
            </defs>

            <path fillRule="evenodd" clipRule="evenodd" d="M12 12V0H24V12C24 13.5913 23.3679 15.1174 22.2426 16.2426C21.1174 17.3679 19.5913 18 18 18C16.4087 18 14.8826 17.3679 13.7574 16.2426C12.6321 15.1174 12 13.5913 12 12Z"
                fill="#fff"
            />
            <path d="M36 0V30H0V0H10.5V12C10.4817 12.99 10.6632 13.9734 11.0336 14.8917C11.404 15.8099 11.9558 16.644 12.6559 17.3441C13.356 18.0442 14.1901 18.596 15.1083 18.9664C16.0266 19.3368 17.01 19.5183 18 19.5C18.99 19.5183 19.9734 19.3368 20.8917 18.9664C21.8099 18.596 22.644 18.0442 23.3441 17.3441C24.0442 16.644 24.596 15.8099 24.9664 14.8917C25.3368 13.9734 25.5183 12.99 25.5 12V0H36Z"
                fill="#fff"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 12V0H24V12C24 13.5913 23.3679 15.1174 22.2426 16.2426C21.1174 17.3679 19.5913 18 18 18C16.4087 18 14.8826 17.3679 13.7574 16.2426C12.6321 15.1174 12 13.5913 12 12Z"
                fill="currentColor"
                mask="url(#mask)"
            />
            <path d="M36 0V30H0V0H10.5V12C10.4817 12.99 10.6632 13.9734 11.0336 14.8917C11.404 15.8099 11.9558 16.644 12.6559 17.3441C13.356 18.0442 14.1901 18.596 15.1083 18.9664C16.0266 19.3368 17.01 19.5183 18 19.5C18.99 19.5183 19.9734 19.3368 20.8917 18.9664C21.8099 18.596 22.644 18.0442 23.3441 17.3441C24.0442 16.644 24.596 15.8099 24.9664 14.8917C25.3368 13.9734 25.5183 12.99 25.5 12V0H36Z"
                fill="currentColor"
                mask="url(#mask)"
            />
        </svg>
    );
};

export default CartIcon;