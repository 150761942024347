import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Link } from '@/components/Languages';
import { useApiQuery } from '@/api';
import { keyBy } from '@/helpers/keyBy';
import { Price, Currency } from '@/components/Price';
import icons from '@/assets/icons';
import styles from './OrderTables.module.css';

const path = '/product-ids?ids[]=';

const Checkbox = (props) => (
  <div className={styles.checkbox}>
    <input
      {...props}
      type="checkbox"
      className={styles.checkboxInput}
    />
    <label htmlFor={props.id} className={styles.checkboxLabel} tabIndex="0">
      <ReactSVG src={icons.checkmark} className={styles.checkboxIcon} />
    </label>
  </div>
);

const SelectItemsTable = ({ products, selectedItems, onSelectItem, onSelectAll }) => {
  const { t } = useTranslation(['main']);

  const ids = Array.from(new Set(products.map(i => i.id))).join('&ids[]=');
  const { data } = useApiQuery({
    name: ['products'],
    path: path + ids,
    skip: !ids,
    mapper: ({ items = [] }, before = {}) => {
      const products = items.reduce((acc, p) => { acc[p.id] = p; return acc }, {});

      return Object.assign({}, before, products);
    },
  })

  if (!data) return null;

  const total = Object.values(selectedItems).reduce((acc, item) => {
    if (!item.checked) return null;
    return acc + keyBy(data, [item.id, 'price', 'en'], 0)
  }, 0);
  const selectedItemsCount = Object.values(selectedItems).reduce((acc, item) => {
    const val = item.checked ? 1 : 0;
    return acc + val;
  }, 0);

  return (
    <div className="table-wrapper">
    <table>
      <tbody>
        <tr className={selectedItemsCount === products.length ? styles.selected : undefined}>
          <th className={styles.head} width="20px">
            <Checkbox
              id="all"
              onChange={e => onSelectAll(e.target.checked)}
              checked={selectedItemsCount === products.length ? true : false}
            />
          </th>
          <th className={styles.head}>{t('product_s')}</th>
          <th width="19.5%" className={styles.head}>{t('id')}</th>
          <th width="13.5%" className={styles.head}>{t('size')}</th>
          <th width="14%" className={styles.head}>{t('qty')}</th>
          <th width="16%" className={styles.head}>{t('price')}(<Currency />)</th>
        </tr>
        {products.map(({ id, size, count, amount }, index) => (
          <tr className={selectedItems[id + index] && selectedItems[id + index].checked ? styles.selected : undefined} key={id + index}>
            <td>
              <Checkbox
                id={id + index}
                onChange={e => onSelectItem(id + index, id, e.target.checked)}
                checked={selectedItems[id + index] && selectedItems[id + index].checked ? true : false}
              />
            </td>
            <td>
              {data[id] && <Link to={`/${data[id].slug}`}><b>{data[id].style_id}</b></Link>}
            </td>
            <td>{data[id] && data[id].slug}</td>
            <td>{size}</td>
            <td>{count}</td>
            <td><Price value={amount / count} showCurrency={false} /></td>
          </tr>
        ))}
        <tr>
          <td colSpan="5" className={styles.totalText}><b>{t('refund_amount')}</b></td>
          <td><Price value={total} showCurrency={false} /></td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default SelectItemsTable;
