import { appSlice } from './index';
const { setItem: setItemAction, pushItem: pushItemAction, sliceItem: sliceItemAction, deleteItem: deleteItemAction } = appSlice.actions;

export const setItem = (path, data, config = {}) => setItemAction([path, data, config]);

export const pushItem = (path, data, config = {}) => pushItemAction([path, data, config]);

export const sliceItem = (path, data, config = {}) => sliceItemAction([path, data, config]);

export const deleteItem = (path, data, config = {}) => deleteItemAction([path, data, config]);
