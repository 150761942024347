import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectItem } from '@/store/app/getters';
import { scrollTo } from '@/components/AnchorsSmoothScroll';
import { ModalList } from '@/components/Modal';
import { sortings } from '@/helpers/sortItems';
import styles from './ProductsFilter.module.css';

const ProductsFilter = ({ title, collection, collectionId, crumbs }) => {
  const { t } = useTranslation('main');
  const [ filterTitle, setFilterTitle ] = useState(null);
  const collections = useSelector(selectItem(['collections'], {}));

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const id = collection ? collection : searchParams.get('collection');
  const sort = searchParams.get('sort_by');

  useEffect(() => {
    if (id) setFilterTitle(id);
    else if (collectionId) setFilterTitle(collections[collectionId]?.name);
    else if (sort) setFilterTitle(t(`sort_${sort}`));
    else setFilterTitle(t('all'));
    // eslint-disable-next-line
  }, [id, sort, collectionId, collections]);

  const baseOptions = [
    {
      name: t('shop_all'),
      isActive: id === null && !collectionId && !sort,
      onClick: (e) => {
        e.preventDefault();
        history.push('/?');
        setTimeout(() => {
          scrollTo('products', false, 'auto');
        }, 50);
      },
      href: `/#products`
    }
  ];

  const options = Object.keys(collections).reduce((prev, value) => {
    if (!value || value === 'null' || !collections[value].name) return prev;

    const name = collections[value].name.toLowerCase();
    if (name === id) collectionId = value;

    const item = {
      value,
      name: `"${name}" ${t('series')}`,
      isActive: collectionId === value,
      onClick: (e) => {
        e.preventDefault();
        history.push(`/?collection=${name}#products`);
        setTimeout(() => {
          scrollTo('products', false, 'auto');
        }, 50);
      },
      href: `/?collection=${name}#products`
    };

    return [...prev, item];
  }, []);

  const sortOptions = sortings.map(value => ({
    value,
    name: t(`sort_${value}`),
    isActive: sort === value,
    onClick: (e) => {
      e.preventDefault();

      history.push(`/?sort_by=${value}`);

      setTimeout(() => scrollTo('products', false), 50);
    },
    href: `/?sort_by=${value}`
  }));

  const divider = { name: 'divider' };

  const control = ({ onClick }) => (
    <span>
      {title && <a onClick={onClick} href="/" className={styles.title}>{title} </a> }
      <a onClick={onClick} href="/">{filterTitle}</a>
      {crumbs && ( crumbs.map((item) => ( <span key={item}> / {item}</span> )) )}
    </span>
  )

  return (
    <ModalList
      control={control}
      items={[
        ...baseOptions,
        divider,
        ...options,
        divider,
        ...sortOptions
      ]}
    />
  );
}

export default ProductsFilter;
