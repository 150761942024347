import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setItem } from '@/store/app/actions';
import {breakpoints} from "@/data/breakpoints";

export const viewport = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
}

const sizes = {
  [breakpoints.sm]: viewport.sm,
  [breakpoints.md]: viewport.md,
  [breakpoints.lg]: viewport.lg,
};

let first = 0;

export const Viewport = () => {
  const dispatch = useDispatch();

  const setSize = size => dispatch(setItem(['viewport'], size));

  useEffect(() => {
    const handleResize = () => {
      const newSize = Object.keys(sizes).reduce(
        (_, cur) => (+cur < window.innerWidth ? sizes[+cur] : _),
        viewport.sm
      );

      setSize(newSize);
    };

    if (first === 0) {
      handleResize();
      first++;
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    //eslint-disable-next-line
  }, []);

  return null;
};
