import { useDispatch, useSelector } from 'react-redux';
import { selectItem } from '@/store/app/getters';
import { setItem, sliceItem } from '@/store/app/actions';
import CartItem from '@/components/Cart/Item';
import styles from './Cart.module.css';

export const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector(selectItem(['user', 'cart'], []));

  const onDelete = (i) => {
    if (!cart.length || cart.length === 1) dispatch(setItem(['statuses', 'isCartOpen'], false));
    dispatch(sliceItem(['user', 'cart'], i));
  }

  return (
    <div className={styles.list}>
      {cart.map(({ id, size }, i) => (
        <CartItem
          key={id + size + i}
          id={id}
          size={size}
          onDelete={() => onDelete(i)}
        />
      ))}
    </div>
  );
}
