import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { rootPath } from '@/api';

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const Request = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!search) return null;
    fetch(rootPath + '/request' + search, {
      method: 'GET',
      headers
    }).then(async res => {
      const data = await res.json();
      if (data) history.push('/order-status' + new URLSearchParams(data).toString());
    });

    // eslint-disable-next-line
  }, [search]);

  return null;
};