const breakpoints = {
  mobileMin: 320,
  mobileMedium: 375,
  mobileMax: 666,
  tabletMin: 667,
  tabletMax: 1023,
  desktopMin: 1024,
  desktopMax: 1399,
  largeDesktopMin: 1400,
  extraLargeDesktopMin: 1536
};

module.exports = {
  breakpoints,
  customMedia: {
    /* 375 и выше */
    '--phone-medium-up': `(min-width: ${breakpoints.mobileMedium}px)`,
    /* 667 и выше */
    '--tablet-up': `(min-width: ${breakpoints.tabletMin}px)`,
    /* 1024 и выше */
    '--desktop-up': `(min-width: ${breakpoints.desktopMin}px)`,
    /* от 320 до 667 */
    '--mobile-only': `(min-width: ${breakpoints.mobileMin}px) and (max-width: ${breakpoints.mobileMax}px)`,
    /* от 667 до 1023 */
    '--tablet-only': `(min-width: ${breakpoints.tabletMin}px) and (max-width: ${breakpoints.tabletMax}px)`,
    /* от 667 до 1023 */
    '--desktop-only': `(min-width: ${breakpoints.desktopMin}px) and (max-width: ${breakpoints.desktopMax}px)`,
    /* 1400 и выше */
    '--large-desktop-up': `(min-width: ${breakpoints.largeDesktopMin}px)`,
    /* 1536 и выше */
    '--extra-large-desktop-up': `(min-width: ${breakpoints.extraLargeDesktopMin}px)`,
  }
}
