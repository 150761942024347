import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // lng: 'en',
    fallbackLng: 'en',
    // whitelist: ['en', 'ru'],
    whitelist: ['en'],
    debug: false,
    ns: ['main'],
    defaultNS: 'main',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      wait: true
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    },
    detection: {
      order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
  });
i18n.changeLanguage();
export default i18n;
