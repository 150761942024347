import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';
import { Price } from '@/components/Price';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from "classnames";
import { sizes as all } from "@/data/sizes";
import { Link } from '@/components/Languages/';
import { sizeParser } from "@/helpers/sizeParser";
import { baseImgUrl } from "@/api";
import { AdaptorVideo } from "@/components/Video/Video";
import { selectItem } from "@/store/app/getters";
import { LazyImage } from '@/components/LazyLoad';
import { ENABLE, SOLD, PREORDER, PREORDER_ALL, getProductStatus } from '@/helpers/productStatus';
import 'swiper/swiper-bundle.min.css';
import styles from './Product.module.css';

SwiperCore.use([Pagination])

export const Product = ({ id, title, style_id, preorder, slug, catalog_gallery, video_catalog, videoMobile, sizes: sizesProps = [], price,
  viewport, listPosition = '' }) => {
  const { t } = useTranslation('main');
  const productStatus = getProductStatus(sizesProps, preorder);
  const lang = useSelector(selectItem(['user', 'lang']));

  const sizes = useMemo(() => (
    all.map(size => (
      <span
        key={size}
        className={classNames(styles.size, size in sizesProps && styles.sizeActive)}
        children={size}
      />
    ))
  ), [sizesProps]);

  const img = useMemo(() => {
    if (['sm', 'md'].includes(viewport))
    return (
      <Swiper
        className={`${styles.slider} product-slider`}
        pagination={['sm', 'md'].includes(viewport)}
        allowSlidePrev={['sm', 'md'].includes(viewport)}
        allowSlideNext={['sm', 'md'].includes(viewport)}
        allowTouchMove={['sm', 'md'].includes(viewport)}
        loop={true}
      >
        {catalog_gallery.map(({ image: path }) => (
          <SwiperSlide key={path} className="swiper-slide-product">
            <LazyImage className={styles.img} src={baseImgUrl + path} alt={title} />
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <AdaptorVideo className={styles.video} video={video_catalog} videoMobile={videoMobile} showLoader={true} />
        </SwiperSlide>
      </Swiper>
    );

    else return (
      <div className={styles.container}>
        <LazyImage classNameWrapper={styles.imageItem} className={styles.img} src={baseImgUrl + catalog_gallery[0].image} alt={title} />
        <AdaptorVideo classNameWrapper={styles.videoItem} className={styles.video} video={video_catalog} videoMobile={videoMobile} showLoader={true} />
      </div>
    )
    //eslint-disable-next-line
  }, [catalog_gallery, viewport]);

  return (
    <Link
      to={`/${slug}`}
      className={classNames(styles.root, 'ga-product-item')}
      data-product-id={slug}
      data-list-position={listPosition}
    >
      {img}
      <div className={styles.body}>
        <div className={styles.sizes}>
          {(productStatus === PREORDER || productStatus === PREORDER_ALL) && (
            <span className={styles.sizesPreorder}>[ {t('pre_order')} {moment(preorder).locale(lang).format('M/YY')} ]</span>
          )}
          {productStatus === SOLD && `[ ${t('sold_out')} ]`}
          {productStatus === ENABLE && sizes}
        </div>
        <h3 className={styles.title}>{style_id ? style_id : title}</h3>
        <span className={styles.price}><Price value={price} /></span>
      </div>
    </Link>
  );
}

export const ProductContainer = ({ price, sizes, ...props }) => {
  const size = useSelector(selectItem(['viewport']));
  const currency = 'en' // useSelector(selectItem(['user', 'lang']));

  return <Product {...props} sizes={sizeParser(sizes)} price={price[currency] || 0} viewport={size} />
}
