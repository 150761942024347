import { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { debounce } from '@/helpers/debounce';
import { SCROLL_OFFSET } from '@/components/AnchorsSmoothScroll';
import styles from './AnchorsMenu.module.css';

const handleScroll = (sizes, setActive) => {
  const scrollTop = window.scrollY,
    windowHeight = window.innerHeight;

  let section = null;

  if (sizes) {
    for (const id in sizes) {
      if (sizes[id] && sizes[id][0] <= scrollTop + windowHeight / 2) section = id;
    }
    if (!section) section = Object.keys(sizes)[0];
  }

  setActive(section);
};

const getAnchorPoints = (sizes, setActive) => {
  // if (isSSR) return;

  const curScroll = window.scrollY - SCROLL_OFFSET;

  for (const id in sizes) {
    const { top, height } = document
      .getElementById(id)
      ?.getBoundingClientRect() || { top: 1 / 0, height: 1 / 0 };

    const min = top + curScroll;
    const max = min + height;

    sizes[id] = [min, max];
  }

  handleScroll(sizes, setActive);
};

const AnchorsMenu = memo(({ items, active, setActive }) => {
  useEffect(() => {
    // if (isSSR) return;
    if (!items) return;

    const sizes = items.reduce((acc, { id }) => {
      acc[id] = null;
      return acc;
    }, {});

    const pointsCallback = debounce(200, () =>
      getAnchorPoints(sizes, setActive)
    );

    const mo = new MutationObserver(pointsCallback);

    const els = [document.getElementById('root')]

    for (const el of els) mo.observe(el, { childList: true, subtree: true });

    const scrollCallback = debounce(10, () =>
      handleScroll(sizes, setActive)
    );

    window.addEventListener('scroll', scrollCallback);
    window.addEventListener('resize', pointsCallback);

    pointsCallback();

    return () => {
      mo.disconnect();
      window.removeEventListener('scroll', scrollCallback);
      window.removeEventListener('resize', pointsCallback);
    };

    //eslint-disable-next-line
  }, [items]);

  const menu = useMemo(
    () =>
      items?.map(({ id, title }, i) => (
        <a
          key={id + title + i}
          href={`#${id}`}
          className={classNames(styles.item, active === id && styles.itemActive)}
        >
          <span>{title}</span>
        </a>
      )),
    [items, active]
  );

  return (
    <div className={styles.wrapper}><div className={styles.list}>{menu}</div></div>
  );
});

export default AnchorsMenu;