const links = {
    social: {
        'subscribe': {
            id: 'subscribe',
            url: '',
            visible: true
        },
        'instagram': {
            id: 'instagram',
            url: 'https://www.instagram.com/idlecorpuniform/',
            visible: true
        },
        'facebook': {
            id: 'facebook',
            url: 'https://www.facebook.com/idlecorpuniform',
            visible: true
        },
        'youtube': {
            id: 'youtube',
            url: 'https://www.youtube.com/channel/UC-3TtlXkM80ce7Q7bVo5ypQ',
            visible: true
        },
        'tiktok': {
            id: 'tiktok',
            url: 'https://www.tiktok.com/@idlecorp',
            visible: true
        },
        'service_email': {
            id: 'service_email',
            url: 'customerservice@idlecorp.com',
            visible: false
        }
    },
    pages: {
        'customer-service': {
            id: 'customer_service',
            url: '/customer-service',
            visible: true,
            /*links: {
                'about-us': {
                    id: 'about_us',
                    url: '#about-us',
                    visible: true
                },
                'contact-us': {
                    id: 'contact_us',
                    url: '#contacts',
                    visible: true
                },
                'how-to-shop': {
                    id: 'how_to_shop',
                    url: '#orders',
                    visible: true
                },
                'payment': {
                    id: 'payment',
                    url: '#payment_methods',
                    visible: true
                },
                'shipping': {
                    id: 'shipping',
                    url: '#shipping_methods',
                    visible: true
                },
                'orders-tracking': {
                    id: 'orders_tracking',
                    url: '#delivery_time',
                    visible: true
                },
                'duties-and-taxes': {
                    id: 'duties_and_taxes',
                    url: '#shipping_costs',
                    visible: true
                },
                'cancellations': {
                    id: 'cancellations',
                    url: '#returns_and_refunds',
                    visible: true
                },
                'returns': {
                    id: 'returns',
                    url: '#returns_and_refunds',
                    visible: true
                },
                'refunds': {
                    id: 'refunds',
                    url: '#returns_and_refunds',
                    visible: true
                }
            }*/
        },

        'order-status': {
            id: 'order_status',
            url: '/order-status',
            visible: true
        },
        'order-cancel': {
            id: 'order_cancel',
            url: '/order-cancel',
            visible: false
        },
        'order-return': {
            id: 'order_return',
            url: '/order-return',
            visible: false
        },

        'terms-and-conditions': {
            id: 'terms_and_conditions',
            url: '/terms-and-conditions',
            visible: true
        },
        'privacy-and-cookie-policy': {
            id: 'privacy_and_cookie_policy',
            url: '/privacy-and-cookie-policy',
            visible: true
        }
    }
}

export default links;
