import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { compose } from 'redux';
import { appSlice } from './app';

const defaultStore = {
  user: {
    country: 'US',
    lang: ''
  },
  statuses: {
    isCartOpen: false,
    isShowText: true
  }
};

const getStore = (preloadedState = defaultStore) => {
  const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  });

  const devTools = !!(
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  );

  return configureStore({reducer: appSlice.reducer, middleware, preloadedState, devTools });
};

export default getStore;
