import { useParams } from 'react-router';
import {ProductContainer} from "@/pages/Product";
import {PageContainer} from "@/pages/Page";

import links from '@/data/links';

export const DispatchContainer = () => {
  const { slug } = useParams();
  const { pages } = links;

  if (!slug) return null;

  if (pages[slug]) return <PageContainer />;
  else return <ProductContainer />;
}
