import {useContext} from 'react';
import {Loading} from '@/components/Loader/Loading';
import {LoaderContext} from '@/context/loaderState';
import styles from './Loader.module.css';

const Loader = () => {
  const {isShowOverlay} = useContext(LoaderContext);
  const {isLoading} = useContext(LoaderContext);

  // useEffect(() => {
  //   toggleOverlay(true);
  //   toggleLoading(true);

  //   //eslint-disable-next-line
  // }, [])

  return (
    <>
      {isShowOverlay &&
        <div className={styles.root}>
          <div className={styles.bg} />
          <div className={styles.logo} />
        </div>
      }
      {isLoading &&
        <Loading className={styles.loadingDown} />
      }
    </>
  );
}

export default Loader;
