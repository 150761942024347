import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PagesList from '@/components/PagesList';
import { HeaderTop } from '@/components/Header';
import { Container, Row } from '@/components/Grid';
import { LoaderContext } from '@/context/loaderState';
import { Notice } from '@/components/Notice/Notice';
import { OrderForm, OrderInfo } from '@/components/Order';
import { selectItem } from '@/store/app/getters';
import { setItem, deleteItem } from '@/store/app/actions';
import links from '@/data/links';
import { rootPath } from '@/api';

import styles from './OrderStatus.module.css';

const { social } = links;

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const OrderStatus = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['main']);
  const { toggleLoading, toggleOverlay } = useContext(LoaderContext);
  const orderInfo = useSelector(selectItem(['orderInfo'], {}));
  const orderId = useSelector(selectItem(['user', 'orderId']));

  const urlOrderId = new URLSearchParams(window.location.search).get('orderId');
  const paysystem = new URLSearchParams(window.location.search).get('paysystem');
  const status = new URLSearchParams(window.location.search).get('status');

  useEffect(() => {
    toggleOverlay(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!orderId && !urlOrderId) return null;
    if (urlOrderId) onFormSubmit({ orderId: urlOrderId }); // from url first
    else onFormSubmit({ orderId: orderId }); // from cache second
    // eslint-disable-next-line
  }, [orderId, urlOrderId]);

  useEffect(() => {
    const hasOrderId = orderId || urlOrderId;
    const isOrderLoad = orderInfo.orderId || orderInfo.error;

    if (!hasOrderId || (hasOrderId && isOrderLoad)) toggleLoading(false);
    else toggleLoading(true);
    // eslint-disable-next-line
  }, [orderId, orderInfo]);

  useEffect(() => {
    if (!orderInfo.orderId) return null;
    window.dataLayer.push({'event': 'check-order-status'});
  }, [orderInfo]);

  const onFormSubmit = formData => {
    let urlParams = { orderId: formData.orderId };
    if (formData.email) { urlParams.email = formData.email };
    if (paysystem) { urlParams.paysystem = paysystem };
    if (status) { urlParams.status = status };

    dispatch(setItem(['user', 'orderId'], formData.orderId));

    fetch(rootPath + '/order-status?' + new URLSearchParams(urlParams).toString(), {
      method: 'GET',
      headers
    }).then(async res => {
      const data = await res.json();

      if (data) {
        if (data.exception) dispatch(setItem(['orderInfo'], { error: true }));
        else dispatch(setItem(['orderInfo'], { ...data.order, orderId: data.orderId, status: data.status }));
      }
    }).catch(err => {
      dispatch(setItem(['orderInfo'], { error: true }));
    })
  };

  const onClearOrderId = () => {
    dispatch(setItem(['user', 'orderId'], ''));
    dispatch(deleteItem(['orderInfo']));
  };

  return (
    <>
      <HeaderTop top={<PagesList title={t('custom_service')} page={'order-status'} />} showFilter={true} showCartInfo={false}>
        <Container>
          <Row noMargin={true}>
            <div className={styles.root}>
              <h1 className={styles.title}>{t('order_status')}</h1>

              {orderInfo.error &&
                <h4>{t('order_not_found_error')}</h4>
              }

              {orderInfo.orderId &&
                <OrderInfo order={orderInfo} onClearOrderId={onClearOrderId} />
              }
              {((!orderId && !urlOrderId) || ((orderId || urlOrderId) && orderInfo.error)) &&
                <OrderForm onSubmit={onFormSubmit} />
              }

              <hr className={styles.divider} />
              <div className={styles.support}>
                {t('if_you_have_any_questions')} <a href={`mailto:${social.service_email.url}`}>{social.service_email.url}</a>
              </div>
            </div>
          </Row>
        </Container>
      </HeaderTop>
      <Notice />
    </>
  )
}
